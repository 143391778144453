import { createBrowserHistory } from 'history';

function doCreateHistory() {
	if (typeof document !== 'undefined') {
		const history = createBrowserHistory();
		history.listen(() => {
			window.scrollTo(0, 0);
		});
		return history;
	}
	return {};
}

const history = doCreateHistory();

export default history;
