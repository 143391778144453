import React, { useState, useRef, useEffect } from 'react';
import { useMutation, ApolloError, MutationFunction } from '@apollo/client';

import { createNodeUnderNode } from '/common/graphql/mutations/nodes';
import './newNodeInput.scss';

const EmojiParser = require('emoji-to-short-name');

interface IProps {
	parentNodeId: string;
	parentName: string;
}

export default function NewNodeInput(props: IProps) {
	const [inputValue, setInputValue] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<string>();

	const hiddenCharacterRef = useRef(null);
	const containerRef = useRef(null);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { parentNodeId, parentName } = props;

	useEffect(() => {
		setInputWidth(Math.max(40, inputValue.length + 2));
		if (inputRef.current) {
			const inputRect = inputRef.current.getBoundingClientRect();
			if (inputRect.top >= 0 && inputRect.bottom <= window.innerHeight) takeFocus();
		}
	}, [inputRef.current, inputValue.length, window]);

	async function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, createNodeUnderNodeFn: MutationFunction) {
		if (e.key === 'Enter') {
			const target = e.target as HTMLInputElement;
			if (target.value.trim().length > 0) {
				setSubmitting(true);
				await createNodeUnderNodeFn({
					variables: { parentNodeId, name: target.value },
				});
				e.preventDefault();
			}
		}
	}

	function takeFocus() {
		if (inputRef && inputRef.current) {
			// @ts-ignore
			inputRef.current.focus();
		}
	}

	function setInputWidth(charCount: number) {
		if (
			inputRef &&
			inputRef.current &&
			containerRef &&
			containerRef.current &&
			hiddenCharacterRef &&
			hiddenCharacterRef.current
		) {
			// Unholy hack to set width without exceeding parent div bounds
			// @ts-ignore
			const maxCharCount = containerRef.current.clientWidth / hiddenCharacterRef.current.getBoundingClientRect().width - 1;
			// @ts-ignore
			inputRef.current.style = `width: ${Math.round(Math.min(maxCharCount, charCount))}ch`;
		}
	}

	async function submitSucceeded() {
		await setInputValue('');
		setInputWidth(40);
		await setSubmitting(false);
		takeFocus();
	}

	async function submitFailed(apolloError: ApolloError) {
		setError(apolloError.message.replace('GraphQL error: ', ''));
		await setSubmitting(false);
		takeFocus();
	}

	function onInputChange(value: string) {
		setInputValue(value);
		setInputWidth(Math.max(40, value.length + 1));
	}

	const emojifiedParentName = EmojiParser.decode(parentName);
	const [createNodeUnderNodeFn] = useMutation(createNodeUnderNode, { onError: submitFailed, onCompleted: submitSucceeded });

	return (
		<div className="newNodeInput">
			<div ref={containerRef}>
				<input
					ref={inputRef}
					disabled={submitting}
					className="newNodeInput standardInput"
					value={inputValue}
					placeholder={`New child of '${emojifiedParentName}'`}
					onChange={(e) => onInputChange(e.target.value)}
					onKeyDown={(e) => onKeyDown(e, createNodeUnderNodeFn)}
				/>
				<span className="hiddenCharacter" ref={hiddenCharacterRef}>
					0
				</span>
			</div>
			<div className="error">
				<span>{error}</span>
			</div>
		</div>
	);
}
