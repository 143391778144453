import ReducerConstants from './globalUserStateReducerActions';

export default function globalUserStateReducer(state, action) {
	if (state === undefined) {
		return { loggedInUserEmail: null, loggedInUserUsername: null, userRootNodeId: null, feedbackRootNodeId: null};
	}

	if (action.type === ReducerConstants.LOGIN_SUCCESS)
		return {
			...state,
			loggedInUserEmail: action.email,
			loggedInUserUsername: action.username,
			userRootNodeId: action.userRootNodeId,
			feedbackRootNodeId: action.feedbackRootNodeId
		};
		
	if (action.type === ReducerConstants.LOGOUT_SUCCESS)
		return {
			...state,
			loggedInUserEmail: null,
			loggedInUserUsername: null,
			userRootNodeId: null,
			feedbackRootNodeId: null,
		};

	return state;
}
