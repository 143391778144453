import React from 'react';
import PropTypes from 'prop-types';
import './nWayToggleButton.scss';

interface IProps {
	index: number;
	onProgramaticSelect: (index: number, onMount: boolean) => any;
	selected?: boolean;
	name: (Node|string)|(Node|string)[];
}

function NWayToggleButton(props: IProps) {
	const { index, onProgramaticSelect, selected, name } = props;

	function onClick(e:React.MouseEvent<HTMLDivElement>) {
		onProgramaticSelect(index, false);
		e.stopPropagation();
	}

	function getClassNames() {
		const classNames = ['nWayToggleButton'];
		if (selected) classNames.push('selectedNWayToggleButton');
		else classNames.push('deselectedNWayToggleButton');
		return classNames;
	}

	function onKeyPress(e:React.KeyboardEvent<HTMLDivElement>) {
		if (e.charCode === 13 || e.charCode === 32) {
			onProgramaticSelect(index, false);
			e.preventDefault();
		}
	}

	return (
		<div
			role="button"
			tabIndex={0}
			onKeyPress={onKeyPress}
			className={getClassNames().join(' ')}
			onClick={onClick}
		>
			{name}
		</div>
	);
}

NWayToggleButton.propTypes = {
	name: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string]))]).isRequired,
	onProgramaticSelect: PropTypes.func.isRequired,
	selected: PropTypes.bool,
	index: PropTypes.number.isRequired,
};

NWayToggleButton.defaultProps = {
	selected: false,
};

export default NWayToggleButton;
