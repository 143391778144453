import React from 'react';
import Status from '../../viewWrappers/status';

export default function NoSuchRouteView() {
	return (
		<Status code={404}>
			<div className="fourOhFour">sorry, we couldn&#39;t find what you were looking for</div>
		</Status>
	);
}
