import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';

import './slidingPanelView.scss';
import Hideable from '/client/app/components/common/hideable';

interface IProps {
	left?: ReactElement;
	right?: ReactElement;
	center: ReactElement;
	leftTitle?: string;
	centerTitle?: string;
	rightTitle?: string;
}

export default function SlidingPanelView(props: IProps) {
	const { left, right, center, leftTitle, centerTitle, rightTitle } = props;

	return (
		<div className="slidingPanelView">
			<div className="left">
				<Hideable hidden={!leftTitle || !left}>
					<div className="header">{leftTitle}</div>
				</Hideable>
				<div className="splitViewBody">{left}</div>
			</div>
			<div className="center">
				<Hideable hidden={!centerTitle || !center}>
					<div className="header">{centerTitle}</div>
				</Hideable>
				<div className="splitViewBody">{center}</div>
			</div>
			<div className="right">
				<Hideable hidden={!rightTitle || !right}>
					<div className="header">{rightTitle}</div>
				</Hideable>
				<div className="splitViewBody">{right}</div>
			</div>
		</div>
	);
}

SlidingPanelView.propTypes = {
	left: PropTypes.node,
	right: PropTypes.node,
	center: PropTypes.node,
	leftTitle: PropTypes.string,
	rightTitle: PropTypes.string,
	centerTitle: PropTypes.string,
};

SlidingPanelView.defaultProps = {
	left: undefined,
	right: undefined,
	center: undefined,
	leftTitle: undefined,
	rightTitle: undefined,
	centerTitle: undefined,
};
