import gql from 'graphql-tag';

export const setTreeViewFilterMode = gql`
	mutation SetTreeViewFilterMode($nodeId: String!, $filterMode: String!) {
		setTreeViewFilterMode(nodeId: $nodeId, filterMode: $filterMode) {
			_id
			filterMode
		}
	}
`;
