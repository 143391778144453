import React from 'react';
import queryString from 'query-string';
import '/client/app/styles/text.scss';
import LoginFormContainer from '../../components/forms/loginForm/loginFormContainer';
import './signIn.scss';

export default function SignInView({ location }) {
	const { search } = location;
	const { dest } = queryString.parse(search);
	return (
		<div className="signInMain">
			<LoginFormContainer destination={dest} />
		</div>
	);
}
