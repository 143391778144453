import React from 'react';
import { ITreeNode } from '/common/interfaces/tree';
import ActiveStackFrame from './activeStackFrame';

import './activeStackPanel.scss';

interface IProps {
	stack: [ITreeNode];
}

export default function ActiveStackPanel(props: IProps) {
	const { stack } = props;

	function stackFrames() {
		if(!stack.length)
			return <span className="none">None</span>;
		return stack.map(node => (
			<ActiveStackFrame key={`activeStackFrame.${node._id}`} name={node.name} />
		));
	}

	return (
		<div role="listbox" className="activeStackPanel">
			<div className="body">
				{stackFrames()}
			</div>
		</div>
	);
}
