import React from 'react';
import { ApolloError } from '@apollo/client';
import Hideable from '/client/app/components/common/hideable';
import TreeNodeControls from '/client/app/components/tree/treeNode/treeNodeHeader/treeNodeControls/treeNodeControls';
import TreeNodeName from './treeNodeName';
import { IProps as PlaceholderProps } from '/client/app/components/tree/treeNode/dropPlaceholder/dropPlaceholder';

interface IProps {
	error?: ApolloError;
	status: string;
	type: string;
	_id: string;
	parent: string;
	path: string;
	index: number;
	lastChildIndex: number;
	parentCount: number;
	name: string;
	selected: boolean;
	editable: boolean;
	editing: boolean;
	setEditing: (editing: boolean) => void;
	rootNode: boolean;
	setSelectedNode: (path: string) => void;
	setExpanded: (expanded: boolean) => void;
	setParentPlaceholderData: (placeholderData: PlaceholderProps) => any;
	setPlaceholderData: (placeholderData: PlaceholderProps) => any;
	setHoveredAbove: (hoveredAbove: boolean) => void;
	setHoveredCenter: (hoveredAbove: boolean) => void;
	setHoveredBelow: (hoveredBelow: boolean) => void;
}

export default function TreeNodeHeader(props: IProps) {
	const {
		error,
		status,
		type,
		editing,
		setEditing,
		_id,
		parent,
		index,
		lastChildIndex,
		rootNode,
		name,
		selected,
		editable,
		path,
		parentCount,
		setSelectedNode,
		setExpanded,
		setParentPlaceholderData,
		setPlaceholderData,
		setHoveredBelow,
		setHoveredCenter,
		setHoveredAbove,
	} = props;

	function selectAndExpand() {
		if (!selected) setSelectedNode(path);
		setExpanded(true);
	}

	function onHeaderKeyPress(e: React.KeyboardEvent<HTMLSpanElement>) {
		if (!editing) {
			if (e.key === 'Enter' || e.key === ' ') {
				selectAndExpand();
				e.preventDefault();
			}
		}
	}

	function onHeaderClick(e: React.MouseEvent<HTMLSpanElement>) {
		if (e.button === 0) selectAndExpand();
	}

	const viewRoot = path.substring(0, path.indexOf('/'));

	return (
		<span tabIndex={0} role="button" onKeyPress={onHeaderKeyPress} className="nodeHeader" onClick={onHeaderClick}>
			<Hideable hidden={!!error}>
				<TreeNodeName
					_id={_id}
					index={index}
					lastChildIndex={lastChildIndex}
					parent={parent}
					name={name}
					status={status}
					type={type}
					rootNode={rootNode}
					editing={editing}
					setEditing={setEditing}
					editable={editable}
					setParentPlaceholderData={setParentPlaceholderData}
					setPlaceholderData={setPlaceholderData}
					setHoveredAbove={setHoveredAbove}
					setHoveredCenter={setHoveredCenter}
					setHoveredBelow={setHoveredBelow}
				/>
			</Hideable>
			<Hideable hidden={!error}>
				<span className="error">{error ? error.message : ''}</span>
			</Hideable>
			<Hideable hidden={!selected}>
				<TreeNodeControls
					editable={editable}
					parentCount={parentCount}
					parentId={parent}
					status={status}
					viewRoot={viewRoot}
					rootNode={rootNode}
					_id={_id}
					type={type}
				/>
			</Hideable>
		</span>
	);
}

TreeNodeHeader.defaultProps = {
	error: undefined,
};
