import React, { useState } from 'react';
import { connect } from 'react-redux';
import '/client/app/styles/text.scss';
import Tree from '/client/app/components/tree/tree';
import SlidingPanelView from '/client/app/components/slidingPanelView/slidingPanelView';
import NodeDetailsContainer from '/client/app/components/nodeDetails/nodeDetailsContainer';
import ActiveStackPanelContainer from '/client/app/components/activeStackPanel/activeStackPanelContainer';

interface IProps {
	rootNodeId: string;
	rootNameOverride?: string;
}

function TreeView(props: IProps) {
	const { rootNodeId, rootNameOverride } = props;
	const [selected, setSelected] = useState<string>();

	const tree = (
		<Tree
			stickyControls
			selectedNode={selected}
			setSelectedNode={setSelected}
			rootNodeNameOverride={rootNameOverride}
			rootNodeEditable={false}
			rootNodeID={rootNodeId}
		/>
	);

	const selectedNodePath = selected ? selected.split('/') : undefined;
	const rightPane = selectedNodePath ? (
		<NodeDetailsContainer
			key={selectedNodePath[selectedNodePath.length - 1]}
			nodeId={selectedNodePath[selectedNodePath.length - 1]}
		/>
	) : undefined;

	const leftPane = <ActiveStackPanelContainer subtreeRootId={rootNodeId} />;

	return (
		<SlidingPanelView
			center={tree}
			left={leftPane}
			right={rightPane}
			leftTitle="In progress"
			rightTitle="Details"
			centerTitle="Tree"
		/>
	);
}

TreeView.defaultProps = {
	rootNameOverride: undefined,
};

const mapStateToProps = (state: any) => ({
	userRootNodeId: state.globalUserState.userRootNodeId,
});

export default connect(mapStateToProps)(TreeView);
