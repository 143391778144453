import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NodeNameEditInput from '/client/app/components/tree/treeNode/treeNodeHeader/nodeNameEditInput/nodeNameEditInput';
import { IProps as PlaceholderProps } from '/client/app/components/tree/treeNode/dropPlaceholder/dropPlaceholder';
import SearchHighlightContext from '/client/app/components/search/searchHighlightContext';
import { faFolder, faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faTag } from '@fortawesome/free-solid-svg-icons';

import './treeNodeName.scss';

const EmojiParser = require('emoji-to-short-name');

interface IProps {
	editing: boolean;
	editable: boolean;
	_id: string;
	parent: string;
	name: string;
	status: string;
	type: string;
	rootNode: boolean;
	index: number;
	lastChildIndex: number;
	setEditing: (editing: boolean) => void;
	setParentPlaceholderData: (placeholderData: PlaceholderProps) => any;
	setPlaceholderData: (placeholderData: PlaceholderProps) => any;
	setHoveredAbove: (hoveredAbove: boolean) => void;
	setHoveredCenter: (hoveredCenter: boolean) => void;
	setHoveredBelow: (hoveredBelow: boolean) => void;
}

export default function TreeNodeName(props: IProps) {
	const {
		_id,
		index,
		lastChildIndex,
		name,
		rootNode,
		status,
		type,
		parent,
		editable,
		editing,
		setEditing,
		setParentPlaceholderData,
		setPlaceholderData,
		setHoveredAbove,
		setHoveredCenter,
		setHoveredBelow,
	} = props;

	const [lastTouch, setLastTouch] = useState<number>();
	const searchString = useContext(SearchHighlightContext);

	function edit() {
		if (editable) setEditing(true);
	}

	function onTouchEnd(e: React.TouchEvent<HTMLSpanElement>) {
		const now = new Date().getTime();
		if (!lastTouch) setLastTouch(now);
		else if (now - lastTouch < 500) {
			e.preventDefault();
			edit();
			setLastTouch(0);
		} else setLastTouch(now);
	}

	function setHoverAbove() {
		setHoveredAbove(true);
		setHoveredBelow(false);
		setHoveredCenter(false);
	}

	function setHoverCenter() {
		setHoveredAbove(false);
		setHoveredBelow(false);
		setHoveredCenter(true);
	}

	function setHoverBelow() {
		setHoveredCenter(false);
		setHoveredAbove(false);
		setHoveredBelow(true);
	}

	function unsetHover() {
		setHoveredAbove(false);
		setHoveredBelow(false);
		setHoveredCenter(false);
	}

	function onDragStart(e: React.DragEvent<HTMLSpanElement>) {
		e.dataTransfer.setData(
			'text/json',
			JSON.stringify({
				_id,
				index,
				name,
				status,
				parentId: parent,
			})
		);
	}

	function dropAbove(e: React.DragEvent<HTMLDivElement>) {
		dropNodeAtIndex(JSON.parse(e.dataTransfer.getData('text/json')), index);
		unsetHover();
		e.preventDefault();
	}

	function dropAsChild(e: React.DragEvent<HTMLDivElement>) {
		const sourceNode = JSON.parse(e.dataTransfer.getData('text/json'));
		const newPlaceholderData = {
			newParentId: _id,
			newIndex: lastChildIndex + 1,
			...sourceNode,
		};
		if (setPlaceholderData) setPlaceholderData(newPlaceholderData);
		unsetHover();
		e.preventDefault();
	}

	function dropBelow(e: React.DragEvent<HTMLDivElement>) {
		dropNodeAtIndex(JSON.parse(e.dataTransfer.getData('text/json')), index + 1);
		unsetHover();
		e.preventDefault();
	}

	function dropNodeAtIndex(sourceNode: any, atIndex: number) {
		if (sourceNode.parentId === parent && sourceNode._id === _id) return;

		let newIndex = atIndex;
		if (parent === sourceNode.parentId && index >= sourceNode.index) newIndex -= 1;

		if (sourceNode.parentId === parent && sourceNode.index === newIndex) return;

		const newPlaceholderData = {
			newParentId: parent,
			newIndex,
			originalIndex: sourceNode.index,
			...sourceNode,
		};
		if (setParentPlaceholderData) setParentPlaceholderData(newPlaceholderData);
	}

	function nameEdit() {
		return (
			<NodeNameEditInput
				_id={_id}
				name={name}
				onCancel={() => setEditing(false)}
				onSuccessfulEdit={() => setEditing(false)}
			/>
		);
	}

	function getSearchTerms() {
		if (searchString.startsWith('"') && searchString.endsWith('"'))
			return [EmojiParser.decode(searchString.substring(1, searchString.length - 1))];
		return searchString.split(' ').map((term) => EmojiParser.decode(term));
	}

	function highlightName(): string {
		if (searchString.trim().length === 0) return EmojiParser.decode(name);
		return getSearchTerms().reduce((accumulator: string, searchTerm: string) => {
			// Escape meta characters
			const regex = new RegExp(`(${searchTerm.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})`, 'ig');
			return accumulator.replace(regex, `<span class="highlight">$1</span>`);
		}, EmojiParser.decode(name));
	}

	function renderIcon() {
		if (type === 'organizational') {
			return (
				<span className="icon">
					<FontAwesomeIcon icon={faFolder} />
				</span>
			);
		}
		if (type === 'task') {
			if (status === 'resolved')
				return (
					<span className="icon">
						<FontAwesomeIcon icon={faSquareCheck} />
					</span>
				);
			return (
				<span className="icon">
					<FontAwesomeIcon icon={faSquare} />
				</span>
			);
		}
		if (type === 'tag') {
			return (
				<span className="icon">
					<FontAwesomeIcon icon={faTag} />
				</span>
			);
		}
		return null;
	}

	function nameDisplay() {
		const highlightedName = <span dangerouslySetInnerHTML={{ __html: highlightName() }} />;
		const classes = ['nodeNameInner'];
		if (type === 'organizational') classes.push('taskNameInner');
		return (
			<span
				onTouchEnd={onTouchEnd}
				draggable={!rootNode}
				onDragStart={onDragStart}
				onDoubleClick={edit}
				className="nodeName"
			>
				{renderIcon()}
				<span className={classes.join(' ')}>{highlightedName}</span>
				<div
					className="topDragTarget"
					onDragOver={(e) => e.preventDefault()}
					onDrop={dropAbove}
					onDragEnter={setHoverAbove}
					onDragLeave={unsetHover}
				/>
				<div
					className="centerDragTarget"
					onDrop={dropAsChild}
					onDragOver={(e) => e.preventDefault()}
					onDragEnter={setHoverCenter}
					onDragLeave={unsetHover}
				/>
				<div
					className="bottomDragTarget"
					onDrop={dropBelow}
					onDragOver={(e) => e.preventDefault()}
					onDragEnter={setHoverBelow}
					onDragLeave={unsetHover}
				/>
			</span>
		);
	}

	if (editing) return nameEdit();
	return nameDisplay();
}
