import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import { treeViewForNode } from '/common/graphql/queries/treeViews';
import { nodeById, descendantsOfNode } from '/common/graphql/queries/nodes';
import TreeNode from './treeNode/treeNode';
import Hideable from '/client/app/components/common/hideable';
import TreeControls from './treeControls';

import './tree.scss';

interface IProps {
	rootNodeID: string;
	showControls: boolean;
	rootNodeEditable: boolean;
	stickyControls: boolean;
	rootNodeNameOverride: string;
	selectedNode?: string;
	setSelectedNode?: (selectedNode: string) => any;
}

export const FilterModeContext = createContext('all');

export default function Tree(props: IProps) {
	const [selectedNode, setSelectedNode] = useState<string>();

	const {
		rootNodeID,
		showControls,
		rootNodeEditable,
		stickyControls,
		rootNodeNameOverride,
		selectedNode: selectedNodeProp,
		setSelectedNode: setSelectedNodeProp,
	} = props;

	const { loading: descendantNodesLoading } = useQuery(descendantsOfNode, {
		errorPolicy: 'all',
		variables: { _id: rootNodeID },
	});
	const { data, loading } = useQuery(nodeById, { errorPolicy: 'all', variables: { _id: rootNodeID } });
	const { data: treeViewData } = useQuery(treeViewForNode, { errorPolicy: 'all', variables: { nodeId: rootNodeID } });
	const { nodeById: nodeData } = data || { nodeById: null };

	const nodeNameOverride: any = {};
	if (nodeData && rootNodeNameOverride) nodeNameOverride.name = rootNodeNameOverride;

	const hasFilterModeValue = showControls && treeViewData && treeViewData.treeViewForNode;
	const filterMode = hasFilterModeValue ? treeViewData.treeViewForNode.filterMode : 'all';

	const hasViewId = treeViewData && treeViewData.treeViewForNode;
	const viewId = hasViewId ? treeViewData.treeViewForNode._id : '';

	return (
		<div className="tree">
			<Hideable hidden={!showControls}>
				<TreeControls sticky={stickyControls} _id={rootNodeID} viewId={viewId} filterMode={filterMode} />
			</Hideable>
			<LoadingWrapper loading={loading || descendantNodesLoading}>
				{() => (
					<FilterModeContext.Provider value={filterMode}>
						<TreeNode
							rootNode
							path={rootNodeID}
							_id={rootNodeID}
							index={0}
							parent={rootNodeID}
							selectedNode={selectedNode || selectedNodeProp}
							setSelectedNode={setSelectedNodeProp || setSelectedNode}
							{...nodeData}
							{...nodeNameOverride}
							editable={rootNodeEditable}
						/>
					</FilterModeContext.Provider>
				)}
			</LoadingWrapper>
		</div>
	);
}

Tree.propTypes = {
	rootNodeID: PropTypes.string.isRequired,
	showControls: PropTypes.bool,
	rootNodeEditable: PropTypes.bool,
	stickyControls: PropTypes.bool,
	rootNodeNameOverride: PropTypes.string,
};

Tree.defaultProps = {
	showControls: true,
	rootNodeEditable: true,
	stickyControls: false,
	rootNodeNameOverride: undefined,
	selectedNode: undefined,
	setSelectedNode: undefined,
};
