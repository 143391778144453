import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '/client/app/styles/text.scss';
import TreeView from '/client/app/views/treeView';

interface IProps {
	feedbackRootNodeId: string;
}

function UserFeedbackView(props: IProps) {
	const { feedbackRootNodeId } = props;

	useEffect(() => {
		document.title = 'Simian Stack - Feedback Tree';
	});

	return (
		<div>
			<TreeView rootNameOverride="Feedback" rootNodeId={feedbackRootNodeId} />
		</div>
	);
}

const mapStateToProps = (state: any) => ({
	feedbackRootNodeId: state.globalUserState.feedbackRootNodeId,
});

export default connect(mapStateToProps)(UserFeedbackView);
