import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation, MutationFunction, ApolloError } from '@apollo/client';
import Hideable from '/client/app/components/common/hideable';
import { linkNode, moveNode } from '/common/graphql/mutations/nodes';
import './dropPlaceholder.scss';

const EmojiParser = require('emoji-to-short-name');

export interface IProps {
	name: string;
	_id: string;
	status: string;
	parentId: string;
	newParentId: string;
	newIndex: number;
	originalIndex: number;
	removeFn: () => void;
}

export default function DropPlaceholder(props: IProps) {
	const { _id, name, parentId, newParentId, newIndex, originalIndex, status, removeFn } = props;
	const [error, setError] = useState<string>();
	const placeholderRef = useRef(null);

	function closeIfClickedOutside(event: any) {
		// @ts-ignore
		if (!placeholderRef.current.contains(event.target)) removeFn();
	}

	useEffect(() => {
		document.addEventListener('mousedown', closeIfClickedOutside);
		return function cleanup() {
			document.removeEventListener('mousedown', closeIfClickedOutside);
		};
	});

	function moveFailed(moveError: ApolloError) {
		setError(moveError.message.replace('GraphQL error: ', ''));
		console.log(moveError.toString());
	}

	async function doMoveNode(moveNodeFn: MutationFunction) {
		await moveNodeFn({
			variables: { _id, parentId, newParentId, newIndex },
		});
	}

	async function doLinkNode(linkNodeFn: MutationFunction) {
		await linkNodeFn({
			variables: { _id, newParentId, newIndex },
		});
	}

	function onComplete() {
		removeFn();
	}

	function onDragStart(e: React.DragEvent<HTMLSpanElement>) {
		e.dataTransfer.setData(
			'text/json',
			JSON.stringify({
				_id,
				index: originalIndex,
				name,
				status,
				parentId,
			})
		);
	}

	const [moveNodeFn] = useMutation(moveNode, { onError: moveFailed, onCompleted: onComplete });
	const [linkNodeFn] = useMutation(linkNode, { onError: moveFailed, onCompleted: onComplete });

	return (
		<div ref={placeholderRef} className={`nodeDropPlaceholder ${status.replace('in progress', 'inProgress')}`}>
			<span className="placeholderLeft">
				<div className="treeNodeLeft">
					<i className="nodeOutlineIcon fas fa-genderless" />
				</div>
			</span>
			<span className="placeholderRight">
				<span className="nodeName" draggable onDragStart={onDragStart}>
					{EmojiParser.decode(name)}
				</span>
				<div>
					<button type="button" onClick={() => doMoveNode(moveNodeFn)} className="placeholderActionButton">
						move
					</button>
					<Hideable hidden={parentId === newParentId}>
						<button type="button" onClick={() => doLinkNode(linkNodeFn)} className="placeholderActionButton">
							link
						</button>
					</Hideable>
					<button type="button" onClick={removeFn} className="placeholderActionButton">
						cancel
					</button>
				</div>
				<div className="error">
					<span>{error}</span>
				</div>
			</span>
		</div>
	);
}

DropPlaceholder.propTypes = {
	name: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	parentId: PropTypes.string.isRequired,
	newParentId: PropTypes.string.isRequired,
	newIndex: PropTypes.number.isRequired,
	removeFn: PropTypes.func.isRequired,
	_id: PropTypes.string.isRequired,
};
