import React, { useState } from 'react';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';
import Hideable from '/client/app/components/common/hideable';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import SlateEditor from '/client/app/components/common/inputs/slateEditor/slateEditor';

import './nodeDescription.scss';

interface IProps {
	bodyHTML: string;
	onEdit: (bodyPlaintext: string, bodyHTML: string) => Promise<any> | any;
	editable: boolean;
}

export default function NodeDescription(props: IProps) {
	const { bodyHTML, onEdit, editable } = props;
	const [error, setError] = useState<string | undefined>();

	async function saveChanges(value: Value) {
		await onEdit(Plain.serialize(value), htmlSerializer.serialize(value));
		setError(undefined);
	}

	return (
		<div className="nodeDescription">
			<SlateEditor
				className="body"
				onSave={(value: Value) => saveChanges(value)}
				onError={(err: Error) => setError(err.message)}
				onEditCancel={() => setError(undefined)}
				displayOnly
				editOnMount={false}
				editable={editable}
				showEditableBorder={false}
				placeholder="detailed description"
				initialValue={bodyHTML ? htmlSerializer.deserialize(bodyHTML) : undefined}
			/>
			<Hideable hidden={!error}>
				<div className="error">{error}</div>
			</Hideable>
		</div>
	);
}
