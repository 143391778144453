import React, { useContext, useEffect } from 'react';
import { activeNodesInSubtree, nodeById } from '/common/graphql/queries/nodes';
import { useApolloClient } from '@apollo/client';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import { NodeStatusChangeDispatcherContext } from '/client/app/listeners/nodeStatusChangeListener';
import ActiveStackPanel from './activeStackPanel';
import { ITreeNode } from '/common/interfaces/tree';
import useCachedQuery from '/common/graphql/useCachedQuery';

interface IProps {
	subtreeRootId: string;
}

interface ActiveNodesInSubtreeData {
	activeNodesInSubtree: ITreeNode[];
}

type OptionalActiveNodesInSubtree = null | ActiveNodesInSubtreeData;

interface NodeByIdData {
	nodeById: ITreeNode;
}

type OptionalNodeByIdData = null | NodeByIdData;

export default function ActiveStackPanelContainer({ subtreeRootId }: IProps) {
	const nodeStatusChangeDispatcher = useContext(NodeStatusChangeDispatcherContext);
	const client = useApolloClient();

	useEffect(() => {
		if (nodeStatusChangeDispatcher) nodeStatusChangeDispatcher.addListener(nodeStatusChanged);
		return function cleanup() {
			if (nodeStatusChangeDispatcher) nodeStatusChangeDispatcher.removeListener(nodeStatusChanged);
		};
	});

	function nodeStatusChanged(_id: string, previousStatus: string, newStatus: string, viewRoot: string) {
		if (!client || viewRoot !== subtreeRootId) return;

		const existingActiveTrees: OptionalActiveNodesInSubtree = client.readQuery({
			query: activeNodesInSubtree,
			variables: { _id: subtreeRootId },
		});

		const newActiveTrees = {
			activeNodesInSubtree: existingActiveTrees ? [...existingActiveTrees.activeNodesInSubtree] : [],
		};

		if (newStatus === 'in progress') {
			const existingNodeById: OptionalNodeByIdData = client.readQuery({ query: nodeById, variables: { _id } });

			if (existingNodeById) newActiveTrees.activeNodesInSubtree.splice(0, 0, existingNodeById.nodeById);
		} else if (previousStatus === 'in progress') {
			newActiveTrees.activeNodesInSubtree = newActiveTrees.activeNodesInSubtree.filter((node) => node._id !== _id);
		} else {
			return;
		}

		client.writeQuery({
			query: activeNodesInSubtree,
			variables: { _id: subtreeRootId },
			data: newActiveTrees,
		});
	}

	const { data, loading } = useCachedQuery(activeNodesInSubtree, {
		errorPolicy: 'all',
		fetchPolicy: 'cache-and-network',
		variables: { _id: subtreeRootId },
	});

	return (
		<LoadingWrapper loading={loading}>
			<ActiveStackPanel stack={data?.activeNodesInSubtree?.filter((node: ITreeNode) => node.status !== 'deleted') || []} />
		</LoadingWrapper>
	);
}
