import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';

export default function createCache() {
	const cache: any = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					nodeById: {
						read(_, { args, toReference }) {
							return toReference({
								__typename: 'Node',
								id: args!._id,
							});
						},
					},
					nodeViewForNode: {
						read(_, { args }) {
							return `NodeView:${args!.nodeId}/${args!.path}`;
						},
					},
				},
			},
		},
		dataIdFromObject: (object) => {
			switch (object.__typename) {
				case 'NodeView': {
					// @ts-ignore
					const { node, path } = object;
					return `NodeView:${node}/${path}`;
				}
				default:
					return defaultDataIdFromObject(object); // fall back to default handling
			}
		},
	});
	return cache;
}
