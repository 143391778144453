import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Higher-order component (HOC) to wrap restricted pages
 */
export default function LoggedOutOnly(BaseComponent, redirect = '/main') {
	class Restricted extends Component {
		constructor(props) {
			super(props);
			this.state = {};
		}

		static getDerivedStateFromProps(nextProps) {
			const { history } = nextProps;

			if (nextProps.isLoggedIn) history.replace({ pathname: redirect });

			return null;
		}

		render() {
			const { isLoggedIn } = this.props;

			if (!isLoggedIn) return <BaseComponent {...this.props} />;

			const { staticContext } = this.props;
			if (staticContext) staticContext.url = redirect;

			return <Redirect to={redirect} />;
		}
	}

	const mapStateToProps = state => ({
		isLoggedIn: !!state.globalUserState.loggedInUserEmail,
	});

	return withRouter(connect(mapStateToProps)(Restricted));
}
