import React from 'react';

import './textSearchInput.scss';

export default function TextSearchInput() {
	function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		if (e.key === 'Enter') {
			const searchString = (e.target as HTMLInputElement).value.trim();
			if (searchString.length > 0) {
				window.location.href = `/search/${encodeURIComponent(searchString)}`;
			}
		}
	}

	return <input className="textSearchInput standardInput" placeholder="Search" onKeyDown={onKeyDown} />;
}
