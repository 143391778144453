// eslint-disable-next-line import/extensions
import io from 'socket.io-client';

require('isomorphic-fetch');

let socket = null;

/**
 * Called on the resulting JSON of any unsuccessful API call.
 *
 * Forces client logout and clearing of local data if the API called because the user's session is no longer valid.
 *
 * If the result of the API call is a failure other than the user not being logged in an error is thrown.
 *
 * @param fetchPromise
 */
async function handleErrors(fetchPromise) {
	const res = await fetchPromise;
	const resultJSON = await res.json();
	if (resultJSON.error || resultJSON.flash) {
		throw new Error(resultJSON.error ? resultJSON.error : resultJSON.flash);
	}
	return resultJSON;
}

/**
 * Gets a socket connection to the server.
 */
export const getServerSocket = () => {
	if (socket === null) {
		socket = io();
	}
	return socket;
};

/**
 * Authenticates the user's credentials.
 *
 * Resolves to the user if the operation succeeded, or null if it fails.
 *
 * @param {*} email
 * @param {*} password
 */
export const authenticate = async (email, password) => {
	try {
		const json = await handleErrors(
			fetch('/auth/authenticate', {
				method: 'POST',
				body: JSON.stringify({
					email,
					password,
				}),
				credentials: 'include',
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
			})
		);
		if (json !== null && json.user !== null) {
			getServerSocket().emit('user authenticated', {});
			return json.user;
		}
		return null;
	} catch (error) {
		throw new Error(error);
	}
};

/**
 * Invalidates the logged in user's session.
 *
 * Resolves to true if the operation succeeded.
 */
export const logout = async () => {
	const json = await handleErrors(
		fetch('/auth/signout', {
			method: 'GET',
			credentials: 'include',
		})
	);
	return json !== null;
};

/**
 * Attempts to create a user with the given credentials.
 *
 * Resolves to either the string "success" or an error string.
 *
 * @param {string} username
 * @param {string} email
 * @param {string} password
 */
export const createAccount = async (username, email, password) => {
	const res = await fetch('/auth/createAccount', {
		method: 'POST',
		body: JSON.stringify({
			username,
			email,
			password,
		}),
		credentials: 'include',
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
	});
	const json = await res.json();
	if (json.result === 'success') return 'success';
	return json.error;
};

/**
 * Attempts to request that a password reset link be sent to the given email address.
 *
 * Resolves to either the string "success" or an error string.
 *
 * @param {string} email
 */
export const requestPasswordResetLink = async email => {
	const res = await fetch('/api/requestPasswordResetLink', {
		method: 'POST',
		body: JSON.stringify({
			email,
		}),
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
	});
	const json = await res.json();
	if (json.result === 'success') return 'success';
	return json.error;
};

/**
 * Attempts to change the user's password using a reset link token.
 *
 * Resolves to either the string "success" or an error string.
 *
 * @param {string} email
 * @param {string} uuid
 * @param {string} password
 */
export const resetPassword = async (email, uuid, password) => {
	const res = await fetch('/api/resetPassword', {
		method: 'POST',
		body: JSON.stringify({
			email,
			uuid,
			password,
		}),
		headers: new Headers({
			'Content-Type': 'application/json',
		}),
	});
	const json = await res.json();
	if (json.result === 'success') return 'success';
	return json.error;
};

/**
 * Attempts to upload a file.
 *
 * Resolves to either the string fileID or an error.
 *
 * @param {FileEntry} fileEntry
 * @param {Function} onProgress
 * @param {Function} onSuccess
 * @param {Function} onError
 */
export const uploadFile = (fileEntry, onProgress, onSuccess, onError) => {
	const data = new FormData();
	data.append('file', fileEntry.file);
	data.append('caption', fileEntry.caption);

	const xhr = new XMLHttpRequest();
	xhr.open('POST', '/api/postMedia', true);
	xhr.upload.addEventListener('progress', onProgress);
	xhr.upload.addEventListener('error', onError);

	xhr.upload.addEventListener('abort', onError);

	xhr.onreadystatechange = () => {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				onSuccess(xhr.responseText);
			} else {
				onError(xhr.responseText);
			}
		}
	};
	xhr.send(data);

	return () => {
		xhr.upload.removeEventListener('progress', onProgress);
		xhr.upload.removeEventListener('error', onError);
		xhr.upload.removeEventListener('abort', onError);
		xhr.onreadystatechange = null;
		xhr.abort();
	};
};
