import { QueryHookOptions, useQuery, useApolloClient, OperationVariables, QueryResult } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useMemo } from 'react';

/**
 * A wrapper for useQuery that checks the cache first, skipping async loading for initial render if its query is satisfied.
 *
 * @param query
 * @param options
 */
export default function useCachedQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
	const client = useApolloClient();

	let data: any;

	try {
		let variables;
		if (options) variables = options.variables;
		data = client.readQuery({ query, variables });
		// eslint-disable-next-line no-empty
	} catch (err) {
		// An error will be thrown by readQuery if data is not cached
	}

	const queryResult = useQuery(query, { ...options, ssr: !data });

	return useMemo(() => (data ? { ...queryResult, data, loading: false } : queryResult), [data, queryResult]);
}
