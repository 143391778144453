import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './mainLayout.scss';
import UserTopBarContainer from '/client/app/components/userTopBar/userTopBarContainer';

interface IProps {
	loggedInUserEmail?: string;
	children?: React.ReactElement | React.ReactElement[];
}

export default function MainLayout({ loggedInUserEmail, children }: IProps) {
	useEffect(() => {
		document.title = 'Simian Stack';
	});

	function getLinks() {
		return [loggedInUserEmail ? getloggedInUserLinks() : getLoggedOutUserLinks(), getEverPresentLinks()];
	}

	function getloggedInUserLinks() {
		return (
			<span key="loggedInUserEmailFooterLinks">
				<Link id="userHomeFooterLink" to="/main">
					user home
				</Link>{' '}
				·{' '}
				<Link id="accountFooterLink" to="/account">
					account
				</Link>{' '}
				·{' '}
				<Link id="preferencesFooterLink" to="/preferences">
					preferences
				</Link>{' '}
				·{' '}
			</span>
		);
	}

	function getLoggedOutUserLinks() {
		return (
			<span key="loggedOutUserFooterLinks">
				<Link id="signinFooterLink" to="/signin">
					sign in
				</Link>{' '}
				·{' '}
				<Link id="resetPasswordFooterLink" to="/resetPassword">
					reset password
				</Link>{' '}
				·{' '}
			</span>
		);
	}

	function getEverPresentLinks() {
		return (
			<span key="evetPresentFooterLinks">
				<Link id="aboutFooterLink" to="/about">about</Link>
				{' '}·{' '}
				<Link id="privacyFooterLink" target="_blank" to="/privacyPolicy">privacy</Link>
			</span>
		);
	}

	return (
		<div>
			<UserTopBarContainer />
			<div className="dynamicallyPaddedContainer">
				<div className="mainContent">{children}</div>
				<div className="innerFooter">
					<div className="innerFooterPadded">
						{getLinks()}
						<br />
						copyright © {new Date().getFullYear()}{' '}
						<a href="https://www.wildcodemonkey.com" rel="noopener noreferrer" target="_blank">
							wild code monkey llc
						</a>{' '}
						&mdash; all rights reserved
					</div>
				</div>
			</div>
		</div>
	);
}

MainLayout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
	loggedInUserEmail: PropTypes.string,
};

MainLayout.defaultProps = {
	children: [],
	loggedInUserEmail: null,
};
