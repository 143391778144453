import React from 'react';
import queryString from 'query-string';
import '/client/app/styles/text.scss';
import CreateAccountFormContainer from '../../components/forms/createAccountForm/createAccountFormContainer';
import './createAccount.scss';

export default function CreateAccountView({ location }) {
	const { dest } = queryString.parse(location.search);
	return (
		<div className="createAccountMain">
			<CreateAccountFormContainer destination={dest} />
		</div>
	);
}
