import React, { ReactElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './armableButton.scss';

interface IProps {
	onConfirm: () => any;
	isDisabled?: boolean;
	className?: string;
	buttonClass?: string;
	confirmCancelClass?: string;
	children: ReactElement | ReactElement[];
}

function ArmableButton(props: IProps) {
	const { onConfirm, isDisabled, className, buttonClass, confirmCancelClass, children } = props;

	const [armed, setArmed] = useState(false);
	const ref = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		document.addEventListener('mousedown', shouldDisarm);
		return () => {
			document.removeEventListener('mousedown', shouldDisarm);
		};
	});

	function shouldDisarm(event: MouseEvent) {
		if (!ref.current!.contains(event.target as Node)) setArmed(false);
	}

	function executeConfirmation() {
		onConfirm();
		setArmed(false);
	}

	function getArmedControls() {
		return (
			<span className="armedButtonControls">
				<button type="button" className={`confirmButton ${buttonClass} ${confirmCancelClass}`} onClick={executeConfirmation}>
					<i className="far fa-check-circle" aria-hidden="true" />
				</button>
				<button
					type="button"
					className={`disarmButton ${buttonClass} ${confirmCancelClass}`}
					onClick={() => setArmed(false)}
				>
					<i className="fa fa-ban" aria-hidden="true" />
				</button>
			</span>
		);
	}

	return (
		<span className={`armableButtonContainer ${className}`} ref={ref}>
			<button
				type="button"
				className={`armableButton ${buttonClass}`}
				onClick={() => setArmed(true)}
				disabled={isDisabled || armed}
			>
				{children}
			</button>
			{armed && !isDisabled ? getArmedControls() : ''}
		</span>
	);
}
ArmableButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	className: PropTypes.string,
	buttonClass: PropTypes.string,
	confirmCancelClass: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string),
	]),
};
ArmableButton.defaultProps = {
	isDisabled: false,
	className: '',
	confirmCancelClass: '',
	children: [],
	buttonClass: 'standardButton',
};

export default ArmableButton;
