const ReducerConstants = {
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',
};

export const loginSuccess = (email, username, userRootNodeId, feedbackRootNodeId) => ({
	type: ReducerConstants.LOGIN_SUCCESS,
	email,
	username,
	userRootNodeId,
	feedbackRootNodeId,
});

export const loginFailure = error => ({
	type: ReducerConstants.LOGIN_FAILURE,
	error,
});

export const logoutSuccess = () => ({
	type: ReducerConstants.LOGOUT_SUCCESS,
});

export default ReducerConstants;
