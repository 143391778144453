import { buffers, eventChannel, END } from 'redux-saga';
import { takeEvery, put, call, take } from 'redux-saga/effects';
import { uploadFile } from '../../client/app/api/backend';
import {
	fileUploadStart,
	fileUploadSuccess,
	fileUploadError,
	fileUploadProgress,
	fileUploadCancelled,
} from '../reducers/fileReducer/fileActions';

export default function* fileUploadFlow() {
	yield takeEvery('FILE_UPLOAD_REQUEST', fileUploadSaga);
}

export function* fileUploadSaga(action) {
	const { fileEntry } = action;
	yield put(fileUploadStart(fileEntry.name));
	const chan = yield call(createUploadFileChannel, fileEntry);
	yield takeEvery(chan, function*({ progress = 0, error, success }) {
		if (error) {
			yield put(fileUploadError(fileEntry.name, error));
			return;
		}
		if (success) {
			yield put(fileUploadSuccess(fileEntry.name, fileEntry.name));
			return;
		}
		yield put(fileUploadProgress(fileEntry, progress));
	});
	yield take(({ type, name }) => type === 'FILE_UPLOAD_CANCEL' && name === fileEntry.name);
	chan.close();
	yield put(fileUploadCancelled(fileEntry.name));
}

export function createUploadFileChannel(fileEntry) {
	return eventChannel(emitter => {
		const onProgress = event => {
			if (event.lengthComputable) {
				const progress = event.loaded / event.total;
				emitter({ progress });
			}
		};
		const onError = () => {
			emitter({ error: new Error('Upload failed') });
			emitter(END);
		};
		const onSuccess = () => {
			emitter({ success: true });
			emitter(END);
		};
		return uploadFile(fileEntry, onProgress, onSuccess, onError);
	}, buffers.sliding(2));
}
