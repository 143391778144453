import React, { Component } from 'react';
import * as GoogleAnalytics from 'react-ga';
import PropTypes from 'prop-types';

GoogleAnalytics.initialize('UA-145586699-1');

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		GoogleAnalytics.set({
			page,
			...options,
		});
		GoogleAnalytics.pageview(page);
	};

	class Wrapped extends Component {
		componentDidMount() {
			const { location } = this.props;
			const { pathname, search } = location;
			trackPage(pathname + search);
		}

		componentDidUpdate(prevProps) {
			const { pathname: previousPathName, search: previousSearch } = prevProps.location;
			const currentPage = previousPathName + previousSearch;
			
			const { location } = this.props;
			const { pathname, search } = location;
			
			const nextPage = pathname + search;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	}

	Wrapped.propTypes = {
		location: PropTypes.shape({
			search: PropTypes.string.isRequired,
			pathname: PropTypes.string.isRequired,
		}).isRequired,
	};

	return Wrapped;
};

export default withTracker;
