import React, { useState } from 'react';
import NewCommentForm from '/client/app/components/comments/newCommentForm';
import { createCommentUnderNode } from '/common/graphql/mutations/comments';
import { useMutation, useQuery } from '@apollo/client';
import { nodeComments } from '/common/graphql/queries/nodes';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import Hideable from '/client/app/components/common/hideable';
import { IComment } from '/common/interfaces/comment';
import Comment from '/client/app/components/comments/comment';
import { loggedInUser } from '/common/graphql/queries/users';

import './comments.scss';

interface IProps {
	nodeId: string;
}

export default function Comments({ nodeId }: IProps) {
	const [createCommentUnderNodeFn] = useMutation(createCommentUnderNode);
	const { data, loading, error } = useQuery(nodeComments, { errorPolicy: 'all', variables: { _id: nodeId } });
	const [creationError, setCreationError] = useState<string | undefined>();
	const {
		data: loggedInUserData,
		loading: loggedInUserLoading,
		error: loggedInUserError,
	} = useQuery(loggedInUser, {
		errorPolicy: 'all',
	});

	async function submit(plaintextValue: string, htmlValue: string) {
		try {
			await createCommentUnderNodeFn({
				variables: {
					bodyHTML: htmlValue,
					bodyPlaintext: plaintextValue,
					parentNodeId: nodeId,
				},
			});
			setCreationError(undefined);
		} catch (err: any) {
			setCreationError(err.message);
		}
	}

	return (
		<div className="comments">
			<LoadingWrapper loading={loading || loggedInUserLoading}>
				{data?.nodeById?.comments.map((comment: IComment) => (
					<Comment
						editable={comment.creator._id === loggedInUserData?.loggedInUser?._id}
						username={comment.creator.username}
						bodyHTML={comment.bodyHTML}
						editDate={comment.editDate}
						creationDate={comment.creationDate}
						id={comment._id}
						key={comment._id}
					/>
				))}
			</LoadingWrapper>
			<NewCommentForm submit={(plaintextValue, htmlValue) => submit(plaintextValue, htmlValue)} />
			<Hideable hidden={!error && !creationError}>
				<div className="error">{error || creationError}</div>
			</Hideable>
			<Hideable hidden={!loggedInUserError}>
				<div className="error">{loggedInUserError}</div>
			</Hideable>
		</div>
	);
}
