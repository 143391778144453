import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutSuccess } from '/common/reducers/globalUserStateReducer/globalUserStateReducerActions';
import UserTopBar from './userTopBar';

class UserTopBarContainer extends React.Component {
	constructor(props) {
		super(props);
		this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
	}

	async onLogoutSuccess() {
		await this.props.logoutSuccess();
		window.location.href = '/';
	}

	render() {
		return (
			<UserTopBar
				loggedInUserEmail={this.props.loggedInUserEmail}
				onLogoutSuccess={this.onLogoutSuccess}
				location={this.props.pathname}
				{...this.props}
			/>
		);
	}
}

UserTopBarContainer.propTypes = {
	pathname: PropTypes.string.isRequired,
	logoutSuccess: PropTypes.func.isRequired,
	loggedInUserEmail: PropTypes.string,
};

UserTopBarContainer.defaultProps = {
	loggedInUserEmail: null,
};

const mapStateToProps = state => ({
	loggedInUserEmail: state.globalUserState.loggedInUserEmail,
	pathname: state.router.location ? state.router.location.pathname : '/',
});

const mapDispatchToProps = dispatch => ({
	logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserTopBarContainer);
