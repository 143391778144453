import gql from 'graphql-tag';

export const createCommentUnderNode = gql`
	mutation CreateCommentUnderNode($bodyHTML: String!, $bodyPlaintext: String!, $parentNodeId: String!) {
		createCommentUnderNode(bodyHTML: $bodyHTML, bodyPlaintext: $bodyPlaintext, parentNodeId: $parentNodeId) {
			_id
			comments {
          _id
          bodyPlaintext
          bodyHTML
          editDate
          creationDate
          creator {
              _id
              username
          }
			}
		}
	}
`;

export const editComment = gql`
	mutation EditComment($id: String!, $bodyHTML: String!, $bodyPlaintext: String!) {
		editComment(id: $id, bodyHTML: $bodyHTML, bodyPlaintext: $bodyPlaintext) {
        _id
        bodyPlaintext
        bodyHTML
        editDate
        creationDate
        creator {
            _id
            username
        }
		}
	}
`;

export const deleteComment = gql`
	mutation DeleteComment($id: String!) {
		deleteComment(id: $id) {
			_id
			comments {
          _id
          bodyPlaintext
          bodyHTML
          editDate
          creationDate
          creator {
              _id
              username
          }
			}
		}
	}
`;
