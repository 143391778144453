import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import queryString from 'query-string';
import Input from '/client/app/components/common/inputs/input/input';
import '/client/app/styles/text.scss';
import '/client/app/styles/controls.scss';
import { requestPasswordResetLink, resetPassword } from '/client/app/api/backend';

import './resetPassword.scss';

export default class ResetPasswordView extends React.Component {
	constructor(props) {
		super(props);
		this.sendResetLink = this.sendResetLink.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.getRequestLinkForm = this.getRequestLinkForm.bind(this);
		this.getChangePasswordForm = this.getChangePasswordForm.bind(this);
		this.onEmailInput = this.onEmailInput.bind(this);
		this.onPasswordConfirmationInput = this.onPasswordConfirmationInput.bind(this);
		this.onPasswordInput = this.onPasswordInput.bind(this);
		const { email } = queryString.parse(this.props.location.search);
		this.state = {
			email: email || '',
			password: '',
			passwordConfirmation: '',
			error: '',
			requestReceived: false,
			inputsDisabled: false,
			submitButtonDisabled: false,
			passwordChanged: false,
		};
	}

	onEmailInput(e) {
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			email: value,
		}));
		return true;
	}

	onPasswordInput(e) {
		console.log('On password input');
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			password: value,
		}));
		return true;
	}

	onPasswordConfirmationInput(e) {
		const { value } = e.target;
		this.setState((prevState) => ({
			...prevState,
			passwordConfirmation: value,
		}));
		return true;
	}

	async changePassword(e) {
		e.preventDefault();
		const { uuid, email } = queryString.parse(this.props.location.search);
		this.setState((prevState) => ({
			...prevState,
			inputsDisabled: true,
		}));
		const result = await resetPassword(email, uuid, this.state.password);
		this.setState((prevState) => ({
			...prevState,
			inputsDisabled: false,
		}));
		if (result === 'success') {
			this.setState((prevState) => ({
				...prevState,
				passwordChanged: true,
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: result,
				passwordChanged: false,
			}));
		}
		return false;
	}

	async sendResetLink(e) {
		e.preventDefault();

		if (this.state.inputsDisabled) return false;

		if (this.state.email && this.state.email.length > 0) {
			this.setState((prevState) => ({
				...prevState,
				inputsDisabled: true,
			}));
			const result = await requestPasswordResetLink(this.state.email);
			this.setState((prevState) => ({
				...prevState,
				inputsDisabled: false,
			}));
			if (result === 'success') {
				this.setState((prevState) => ({
					...prevState,
					requestReceived: true,
					error: '',
				}));
			} else {
				this.setState((prevState) => ({
					...prevState,
					error: result,
					requestReceived: false,
				}));
			}
		} else {
			this.setState((prevState) => ({
				...prevState,
				error: 'email address is required',
				requestReceived: false,
			}));
		}
		return false;
	}

	getRequestLinkForm() {
		return (
			<form onSubmit={this.sendResetLink}>
				<Input
					type="text"
					disabled={this.state.inputsDisabled}
					placeholder="email address"
					onInput={this.onEmailInput}
					value={this.state.email}
					name="email"
					className="resetPasswordFormField"
					uid="reset_password_email"
				/>
				<br />
				<input
					type="submit"
					className="standardButton resetPasswordButton"
					disabled={this.state.inputsDisabled || validator.isEmail(this.state.email) === false}
					value="send password reset link"
				/>
				<br />
				<div className="resetPasswordError">{this.state.error}</div>
				{this.state.requestReceived ? (
					<div className="resetPasswordSuccess">your password reset link request has been received</div>
				) : (
					[]
				)}
			</form>
		);
	}

	getChangePasswordForm() {
		return (
			<form onSubmit={this.changePassword}>
				<Input
					type="password"
					disabled={this.state.inputsDisabled}
					placeholder="enter new password"
					onInput={this.onPasswordInput}
					name="password"
					uid="reset_password_password"
				/>{' '}
				<Input
					type="password"
					disabled={this.state.inputsDisabled}
					placeholder="confirm new password"
					onInput={this.onPasswordConfirmationInput}
					name="password2"
					uid="reset_password_password2"
				/>{' '}
				<input
					type="submit"
					className={`standardButton resetPasswordButton${this.state.inputsDisabled ? ' disabled' : ''}`}
					value="change password"
				/>
				<br />
				<div className="resetPasswordError">{this.state.error}</div>
				{this.state.passwordChanged ? <div className="resetPasswordSuccess">your password has been changed</div> : []}
			</form>
		);
	}

	render() {
		const { uuid, email } = queryString.parse(this.props.location.search);
		return (
			<div className="resetPasswordForm">
				<p>
					If you are unable to remember your password you can recover your account by resetting it.
					<br />
					If the email address you provide is associated with a valid account a password reset link will be received.
				</p>
				{uuid && email ? this.getChangePasswordForm() : this.getRequestLinkForm()}
			</div>
		);
	}
}

ResetPasswordView.propTypes = {
	location: PropTypes.shape({
		search: PropTypes.string.isRequired,
	}).isRequired,
};
