import gql from 'graphql-tag';

export const loggedInUser = gql`
	query loggedInUser {
		loggedInUser {
			_id
			username
		}
	}
`;
