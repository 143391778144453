import React from 'react';
import '/client/app/styles/text.scss';
import TreeView from '/client/app/views/treeView';

interface IProps {
	urlParameters: any;
}

function SubtreeView(props: IProps) {
	const { urlParameters } = props;
	const { nodeId } = urlParameters;

	return <TreeView rootNodeId={nodeId} />;
}

export default SubtreeView;
