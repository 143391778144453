import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom';
import createStoreWithHistory from '/common/store';
import history from '/common/browserHistory';
import MainLayoutContainer from '../components/mainLayout/mainLayoutContainer';
import LoggedInOnly from '../viewWrappers/loggedInOnly';
import LoggedOutOnly from '../viewWrappers/loggedOutOnly';
import withTracker from '../viewWrappers/withTracker';
import CreateAccountView from './createAccount/createAccount';
import SignInView from './signIn/signIn';
import UserHomeView from './userHome';
import UserFeedbackView from './feedback';
import SubtreeView from './subtree';
import SearchView from './search';
import AccountViewContainer from './account/accountContainer';
import PrivacyPolicyView from './privacyPolicy/privacyPolicy';
import AboutView from './about';
import SettingsView from './preferences';
import EmailConfirmationSuccessView from './emailConfirmationSuccess';
import EmailConfirmationFailureView from './emailConfirmationFailure';
import ResetPasswordView from './resetPassword/resetPassword';
import NoSuchRouteView from './noSuchRoute/noSuchRoute';
import ComponentWithRegex from '../viewWrappers/componentWithRegex';

export const mainLayout = (
	<MainLayoutContainer>
		<Switch>
			<Route path="/emailConfirmationSuccess" component={withTracker(EmailConfirmationSuccessView)} />
			<Route path="/emailConfirmationFailure" component={withTracker(EmailConfirmationFailureView)} />
			<Route path="/createAccount" component={LoggedOutOnly(withTracker(CreateAccountView))} />
			<Route path="/resetPassword" component={LoggedOutOnly(withTracker(ResetPasswordView))} />
			<Route path="/signin" component={LoggedOutOnly(withTracker(SignInView))} />
			<Route path="/main" component={LoggedInOnly(withTracker(UserHomeView))} />
			<Route path="/feedback" component={LoggedInOnly(withTracker(UserFeedbackView))} />
			<Route exact path="/subtree/:nodeId([A-Za-z_0-9]+)" component={LoggedInOnly(withTracker(ComponentWithRegex(SubtreeView)))} />
			<Route exact path="/search/:searchString(.+)" component={LoggedInOnly(withTracker(ComponentWithRegex(SearchView)))} />
			<Route path="/account" component={LoggedInOnly(withTracker(AccountViewContainer))} />
			<Route path="/about" component={withTracker(AboutView)} />
			<Route path="/privacyPolicy" component={withTracker(PrivacyPolicyView)} />
			<Route path="/preferences" component={LoggedInOnly(withTracker(SettingsView))} />
			<Route exact path="/" component={LoggedOutOnly(withTracker(SignInView))} />

			<Route component={NoSuchRouteView} />
		</Switch>
	</MainLayoutContainer>
);

export default function IndexView() {
	return (
		<Provider store={createStoreWithHistory(history)}>
			<ConnectedRouter history={history}>{mainLayout}</ConnectedRouter>
		</Provider>
	);
}
