import { createContext } from 'react';

export type nodeStatusChangedFn = (id:string, previousStatus:string, newStatus: string, viewRoot: string) => void;

export default class NodeStatusChangeDispatcher {
	private listeners: nodeStatusChangedFn[];

	constructor() {
		this.listeners = [];
		this.addListener = this.addListener.bind(this);
		this.removeListener = this.removeListener.bind(this);
		this.notifyListeners = this.notifyListeners.bind(this);
	}

	addListener(listener: nodeStatusChangedFn) {
		this.listeners.push(listener);
	}

	removeListener(listener: nodeStatusChangedFn) {
		const index = this.listeners.indexOf(listener);
		if(index >= 0)
			this.listeners.splice(index, 1);
	}

	notifyListeners(id:string, previousStatus:string, newStatus: string, viewRoot: string) {
		if(previousStatus === newStatus)
			throw new Error('status change called with equal previous/new values');

		this.listeners.forEach(listener => listener(id, previousStatus, newStatus, viewRoot));
	}
}

export const NodeStatusChangeDispatcherContext = createContext<NodeStatusChangeDispatcher>(new NodeStatusChangeDispatcher());
