import React from 'react';
import '/client/app/styles/text.scss';

export default function AboutView() {
	return (
		<div className="aboutContainer">
			<span>coming soon...</span>
		</div>
	);
}
