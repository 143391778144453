import ReducerConstants from './fileActions';

export const defaultState = [];

const fileReducer = (state, action) => {
	if (state === undefined) {
		return defaultState;
	}
	switch (action.type) {
		case ReducerConstants.FILE_LIST_UPDATE: {
			return [...action.fileList];
		}
		case ReducerConstants.FILE_UPLOAD_REQUEST: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.fileEntry.name) {
					fileEntry.isUploading = true;
				}
				return fileEntry;
			});
		}
		case ReducerConstants.FILE_UPLOAD_CANCELLED: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.name) {
					fileEntry.isUploading = false;
				}
				return fileEntry;
			});
		}
		case ReducerConstants.FILE_UPLOAD_SUCCESS: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.name) {
					fileEntry.isUploading = false;
					fileEntry.hasUploaded = true;
					fileEntry.id = action.id;
				}
				return fileEntry;
			});
		}
		case ReducerConstants.FILE_UPLOAD_ERROR: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.name) {
					fileEntry.isUploading = false;
					fileEntry.error = action.error.toString();
				}
				return fileEntry;
			});
		}
		case ReducerConstants.FILE_UPLOAD_COMPLETE: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.name) {
					fileEntry.isUploading = false;
					fileEntry.hasUploaded = true;
				}
				return fileEntry;
			});
		}
		case ReducerConstants.FILE_UPLOAD_PROGRESS: {
			return state.map(fileEntry => {
				if (fileEntry.name === action.fileEntry.name) {
					fileEntry.uploadProgress = action.progress;
				}
				return fileEntry;
			});
		}
		default:
			return state;
	}
};

export default fileReducer;
