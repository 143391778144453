const ReducerConstants = {
	FILE_UPLOAD_REQUEST: 'FILE_UPLOAD_REQUEST',
	FILE_UPLOAD_CANCEL: 'FILE_UPLOAD_CANCEL',
	FILE_UPLOAD_CANCELLED: 'FILE_UPLOAD_CANCELLED',
	FILE_UPLOAD_START: 'FILE_UPLOAD_START',
	FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
	FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
	FILE_UPLOAD_COMPLETE: 'FILE_UPLOAD_COMPLETE',
	FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
	FILE_LIST_UPDATE: 'FILE_LIST_UPDATE',
};
export default ReducerConstants;

export const updateFiles = fileList => ({
	type: ReducerConstants.FILE_LIST_UPDATE,
	fileList,
});

export const fileUploadRequest = fileEntry => ({
	type: ReducerConstants.FILE_UPLOAD_REQUEST,
	fileEntry,
});

export const fileUploadCancel = name => ({
	type: ReducerConstants.FILE_UPLOAD_CANCEL,
	name,
});

export const fileUploadCancelled = name => ({
	type: ReducerConstants.FILE_UPLOAD_CANCELLED,
	name,
});

export const fileUploadStart = name => ({
	type: ReducerConstants.FILE_UPLOAD_START,
	name,
});

export const fileUploadSuccess = (name, id) => ({
	type: ReducerConstants.FILE_UPLOAD_SUCCESS,
	name,
	id,
});

export const fileUploadError = (name, error) => ({
	type: ReducerConstants.FILE_UPLOAD_ERROR,
	name,
	error,
});

export const fileUploadComplete = name => ({
	type: ReducerConstants.FILE_UPLOAD_COMPLETE,
	name,
});

export const fileUploadProgress = (fileEntry, progress) => ({
	type: ReducerConstants.FILE_UPLOAD_PROGRESS,
	fileEntry,
	progress,
});
