import React from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateValue } from '/common/reducers/common/input/inputValueReducer/inputValueReducerActions';
import Input from './input';

class InputContainer extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	valueChanged(e) {
		if (this.props.fieldValueUpdated) this.props.fieldValueUpdated(this.props.uid, e.target.value);
		if (this.props.onInput) return this.props.onInput(e);
		return true;
	}

	render() {
		const { onInput, ...existingProps } = this.props;
		// Allow value to be overridden
		const value = this.props.value || this.props.inputValue;
		return <Input value={value} onInput={this.valueChanged} {...existingProps} />;
	}
}

InputContainer.propTypes = {
	inputValue: PropTypes.string,
	uid: PropTypes.string.isRequired,
	fieldValueUpdated: PropTypes.func,
	onInput: PropTypes.func,
	pattern: PropTypes.string,
	enforcePatternOnChange: PropTypes.bool,
	className: PropTypes.string,
	placeholder: PropTypes.string,
};

InputContainer.defaultProps = {
	inputValue: '',
	onInput: undefined,
	pattern: undefined,
	enforcePatternOnChange: false,
	className: undefined,
	placeholder: undefined,
	fieldValueUpdated: undefined,
};

const mapStateToProps = (state, ownProps) => ({
	inputValue: state.inputValueState.fieldValuesByUID[ownProps.uid],
});

const mapDispatchToProps = dispatch => ({
	fieldValueUpdated: (uid, value) => dispatch(updateValue(uid, value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InputContainer);
