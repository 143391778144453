import React from 'react';
import PropTypes from 'prop-types';
import Loading from './loading';
import isFunction from '/client/app/util/isFunction';

interface IProps {
	loading: boolean;
	children: any;
}

export default function LoadingWrapper(props: IProps) {
	const { loading } = props;
	if (loading) return <Loading />;
	return isFunction(props.children) ? props.children() : props.children;
}

LoadingWrapper.propTypes = {
	loading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	children: PropTypes.any,
};

LoadingWrapper.defaultProps = {
	children: undefined,
};
