import React from 'react';

/**
 * Higher-order component (HOC) to take route match parameters and pass them to the wrapped component as 'urlParameters'
 */
export default function ComponentWithRegex(BaseComponent) {
	return function ({ match }) {
		return <BaseComponent urlParameters={match.params} />;
	};
}
