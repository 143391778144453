import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Account from './account';

class AccountContainer extends React.Component {
	constructor(props) {
		super(props);
		this.render = this.render.bind(this);
	}

	render() {
		const { username, userEmail } = this.props;
		return <Account userEmail={userEmail} username={username} />;
	}
}

AccountContainer.propTypes = {
	userEmail: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	userEmail: state.globalUserState.loggedInUserEmail,
	username: state.globalUserState.loggedInUserUsername,
});

export default connect(mapStateToProps)(AccountContainer);
