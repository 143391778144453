import React, { useState, useContext, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { useMutation, ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import ArmableButton from '/client/app/components/common/armableButton/armableButton';
import Hideable from '/client/app/components/common/hideable';
import { setNodeStatus, deleteNode, removeNodeFromParent, setNodeType } from '/common/graphql/mutations/nodes';
import './treeNodeControls.scss';
import { NodeStatusChangeDispatcherContext } from '/client/app/listeners/nodeStatusChangeListener';

interface IProps {
	_id: string;
	status: string;
	parentCount: number;
	parentId: string;
	editable: boolean;
	viewRoot: string;
	rootNode: boolean;
	type: string;
}

export default function TreeNodeControls(props: IProps) {
	const { _id, status, parentCount, parentId, editable, viewRoot, rootNode, type } = props;
	const [error, setError] = useState<string>();
	const [changingType, setChangingType] = useState(false);

	const nodeStatusChangeDispatcher = useContext(NodeStatusChangeDispatcherContext);

	function optionsForStatus(newStatus: string) {
		return {
			variables: { _id, status: newStatus },
			optimisticResponse: {
				__typename: 'Mutation',
				setNodeStatus: {
					__typename: 'Node',

					_id,
					status: newStatus,
					lastStatusModificationDate: new Date().toString(),
				},
			},
		};
	}

	function setApolloError(apolloError: ApolloError) {
		setError(apolloError.message.replace('GraphQL error: ', ''));
	}

	function statusChanged(newStatus: string): void {
		if (nodeStatusChangeDispatcher) nodeStatusChangeDispatcher.notifyListeners(_id, status, newStatus, viewRoot);
	}

	const [removeNodeFromParentFn] = useMutation(removeNodeFromParent, { onError: setApolloError });
	const [deleteNodeFn] = useMutation(deleteNode, { onError: setApolloError });
	const [setNodeTypeFn] = useMutation(setNodeType, { onError: setApolloError });
	const [setNodeStatusFn] = useMutation(setNodeStatus, {
		onCompleted: (data: any) => statusChanged(data.setNodeStatus.status),
		onError: setApolloError,
	});

	async function updateStatus(newStatus: string) {
		await setNodeStatusFn(optionsForStatus(newStatus));
	}

	async function start() {
		await updateStatus('in progress');
	}

	async function open() {
		await updateStatus('open');
	}

	async function resolve() {
		await updateStatus('resolved');
	}

	async function reject() {
		await updateStatus('rejected');
	}

	async function unlinkOrDelete() {
		if (parentCount > 1) {
			await removeNodeFromParentFn({
				variables: {
					_id,
					parentId,
				},
			});
		} else {
			await deleteNodeFn({
				variables: { _id },
			});
		}
	}

	async function changeType(e: ChangeEvent<HTMLSelectElement>) {
		try {
			setChangingType(true);
			await setNodeTypeFn({
				variables: {
					_id,
					type: e.target.value,
				},
				onError: setApolloError,
			});
			if (e.target.value !== 'task' && status === 'in progress') await open();
		} catch (err) {
			// Error handled via onError
		}
		setChangingType(false);
	}

	return (
		<div className="treeNodeControls">
			<div className="armableButtons">
				<Hideable hidden={!editable}>
					<select value={type} disabled={changingType} onChange={changeType}>
						<option value="task">task</option>
						<option value="organizational">category</option>
						<option value="tag">tag</option>
					</select>
					<Hideable hidden={type !== 'task'}>
						<button type="button" className="tncButton" onClick={status === 'in progress' ? open : start}>
							{status === 'in progress' ? 'stop' : 'start'}
						</button>
						<ArmableButton
							className="tncArmable"
							confirmCancelClass="tncCCButton"
							buttonClass="tncButton"
							onConfirm={status === 'resolved' ? open : resolve}
						>
							<span>{status === 'resolved' ? 'unresolve' : 'resolve'}</span>
						</ArmableButton>

						<ArmableButton
							className="tncArmable"
							confirmCancelClass="tncCCButton"
							buttonClass="tncButton"
							onConfirm={status === 'rejected' ? open : reject}
						>
							<span>{status === 'rejected' ? 'unreject' : 'reject'}</span>
						</ArmableButton>
					</Hideable>
					<ArmableButton
						className="tncArmable"
						confirmCancelClass="tncCCButton"
						buttonClass="tncButton"
						onConfirm={() => unlinkOrDelete()}
					>
						<span>{parentCount > 1 ? 'unlink' : 'delete'}</span>
					</ArmableButton>
				</Hideable>

				<Hideable hidden={rootNode}>
					<Link className="tncButton" to={`/subtree/${_id}`}>
						subtree
					</Link>
				</Hideable>
			</div>
			<div className="error">
				<span>{error}</span>
			</div>
		</div>
	);
}

TreeNodeControls.propTypes = {
	_id: PropTypes.string.isRequired,
	viewRoot: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	editable: PropTypes.bool,
};

TreeNodeControls.defaultProps = {
	editable: true,
};
