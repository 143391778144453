import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginFailure } from '/common/reducers/globalUserStateReducer/globalUserStateReducerActions';
import {
	loginFormLoginAttempt,
	loginFormLoginSuccess,
	loginFormLogoutSuccess,
	loginFormLoginFailure,
	loginFormValuesUpdated,
} from '/common/reducers/loginFormReducer/loginFormReducerActions';
import { authenticate } from '/client/app/api/backend';
import Input from '/client/app/components/common/inputs/input/input';
import '/client/app/styles/controls.scss';
import './loginForm.scss';

class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.attemptLogin = this.attemptLogin.bind(this);
		this.onEmailInput = this.onEmailInput.bind(this);
		this.onPasswordInput = this.onPasswordInput.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.createAccount = this.createAccount.bind(this);
	}

	onEmailInput(e) {
		this.props.loginFormValuesUpdated(e.target.value, this.props.password);
	}

	onPasswordInput(e) {
		this.props.loginFormValuesUpdated(this.props.email, e.target.value);
	}

	resetPassword(e) {
		e.preventDefault();
		window.location.href = `/resetPassword?email=${encodeURIComponent(this.props.email)}`;
		return false;
	}

	createAccount(e) {
		e.preventDefault();
		window.location.href = `/createAccount?dest=${encodeURIComponent(this.props.destination)}`;
		return false;
	}

	attemptLogin(e) {
		e.preventDefault();

		const eml = this.props.email.trim();
		const passwd = this.props.password;

		const onLoginFailure = this.props.loginFailure;

		this.props.loginFormLoginAttempt();
		authenticate(eml, passwd)
			.then(user => {
				if (user !== null) {
					window.location.href = this.props.destination;
				} else {
					onLoginFailure('general login failure.');
				}
			})
			.catch(error => {
				onLoginFailure(error.message.replace(/^Error: /, ''));
				console.log(error);
			});

		return false;
	}

	render() {
		return (
			<form name="loginForm" onSubmit={this.attemptLogin}>
				<Input
					type="text"
					name="email"
					id="email"
					placeholder="username or email"
					value={this.props.email}
					onChange={this.onEmailInput}
					className="loginFormField"
					autoFocus
				/>
				<Input
					type="password"
					id="password"
					name="password"
					placeholder="password"
					value={this.props.password}
					onChange={this.onPasswordInput}
					className="loginFormField"
				/>
				<br />
				<button className="standardButton loginButton" name="loginButton" type="submit" disabled={this.props.submitDisabled}>
					<b>{this.props.submitButtonText}</b>
				</button>
				<button type="button" className="standardButton loginButton" name="resetPasswordButton" onClick={this.resetPassword}>
					reset password
				</button>
				<button type="button" className="standardButton loginButton" name="createAccountButton" onClick={this.createAccount}>
					create account
				</button>
				<p className="loginErrorLabel">{this.props.error.toLowerCase()}</p>
			</form>
		);
	}
}

LoginForm.propTypes = {
	email: PropTypes.string,
	password: PropTypes.string,
	submitButtonText: PropTypes.string.isRequired,
	submitDisabled: PropTypes.bool,
	error: PropTypes.string,
	loginFailure: PropTypes.func.isRequired,
	loginFormLoginAttempt: PropTypes.func.isRequired,
	loginFormValuesUpdated: PropTypes.func.isRequired,
	destination: PropTypes.string,
};

LoginForm.defaultProps = {
	email: '',
	password: '',
	submitDisabled: true,
	error: null,
	destination: 'main',
};

const mapDispatchToProps = dispatch => ({
	loginFailure: error => dispatch(loginFailure(error)),
	loginFormLoginAttempt: () => dispatch(loginFormLoginAttempt()),
	loginFormLoginSuccess: () => dispatch(loginFormLoginSuccess()),
	loginFormLogoutSuccess: () => dispatch(loginFormLogoutSuccess()),
	loginFormLoginFailure: error => dispatch(loginFormLoginFailure(error)),
	loginFormValuesUpdated: (email, password) => dispatch(loginFormValuesUpdated(email, password)),
});

export default connect(
	null,
	mapDispatchToProps
)(LoginForm);
