import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NWayToggleButton from './nWayToggleButton';

import './nWayToggle.scss';

interface IProps {
	resetOnMount?: boolean;
	selectedOption?: number;
	defaultOption?: number;
	onProgramaticSelect: (index: number, onMount: boolean) => any;
	options: (Node|string)[];
}

/**
 * A button allowing the exclusive selection of up to one of several options.
 *
 * Supports optionally auto-selecting a default option and resetting to the default on component mount.
 */
function NWayToggle(props:IProps) {
	const {resetOnMount, selectedOption, defaultOption, onProgramaticSelect, options} = props;

	useEffect(() => {
		if (shouldApplyDefaultSelection()) onProgramaticSelect!(defaultOption!, true);
		else onProgramaticSelect!(selectedOption!, true);
	})

	function shouldApplyDefaultSelection() {
		return (resetOnMount || !selectedOption) && defaultOption && onProgramaticSelect;
	}

	function getButtonForOptionAtIndex(option: Node|string, index: number) {
		return (
			<NWayToggleButton
				key={index}
				name={option}
				selected={index === selectedOption}
				onProgramaticSelect={onProgramaticSelect}
				index={index}
			/>
		);
	}

	function getOptionButtons() {
		return options.map((option, index) => getButtonForOptionAtIndex(options[index], index));
	}

	return <div className="nWayToggle">{getOptionButtons()}</div>;
}

NWayToggle.propTypes = {
	resetOnMount: PropTypes.bool,
	selectedOption: PropTypes.number,
	defaultOption: PropTypes.number,
	onProgramaticSelect: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string])).isRequired,
};

NWayToggle.defaultProps = {
	resetOnMount: false,
	selectedOption: null,
	defaultOption: null,
};

export default NWayToggle;
