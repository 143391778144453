import gql from 'graphql-tag';

export const nodesById = gql`
	query nodes($ids: [String]!) {
		nodesById(ids: $ids) {
			_id
			creationDate
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
			type
		}
	}
`;

export const nodeById = gql`
	query nodes($_id: String!) {
		nodeById(_id: $_id) {
			_id
			creationDate
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
			type
		}
	}
`;

export const nodeDescription = gql`
	query nodes($_id: String!) {
		nodeById(_id: $_id) {
			_id
			descriptionPlaintext
			descriptionHTML
			creator {
				_id
				username
			}
		}
	}
`;

export const nodeComments = gql`
	query nodes($_id: String!) {
		nodeById(_id: $_id) {
			_id
			comments {
				_id
				bodyPlaintext
				bodyHTML
				editDate
				creationDate
				creator {
					_id
					username
				}
			}
		}
	}
`;

export const descendantsOfNode = gql`
	query Nodes($_id: String!, $depth: Int) {
		descendantsOfNode(_id: $_id, depth: $depth) {
			_id
			creationDate
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
			type
		}
	}
`;

export const activeNodesInSubtree = gql`
	query Nodes($_id: String!, $depth: Int) {
		activeNodesInSubtree(_id: $_id, depth: $depth) {
			_id
			creationDate
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
			type
		}
	}
`;

export const nodesBySearchText = gql`
	query Nodes($searchString: String!, $limit: Int, $skip: Int) {
		nodesBySearchText(searchString: $searchString, limit: $limit, skip: $skip) {
			_id
			creationDate
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
			type
		}
	}
`;
