import { trackEvent } from '@redux-beacon/google-analytics';

const eventMap = {
	NWAY_TOGGLE_OPTION_USER_SELECTED: trackEvent(action => ({
		category: 'NWayToggle',
		action: 'Selected',
		label: `${action.uid}:${action.option}`,
		value: 1,
	})),
};

export default eventMap;
