import React from 'react';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import { useMutation, useQuery } from '@apollo/client';
import { nodeDescription } from '/common/graphql/queries/nodes';
import Hideable from '/client/app/components/common/hideable';
import NodeDescription from '/client/app/components/nodeDescription/nodeDescription';
import { loggedInUser } from '/common/graphql/queries/users';
import { setNodeDescription } from '/common/graphql/mutations/nodes';

interface IProps {
	nodeId: string;
}

export default function NodeDescriptionContainer(props: IProps) {
	const { nodeId } = props;
	const { data, loading, error } = useQuery(nodeDescription, { errorPolicy: 'all', variables: { _id: nodeId } });
	const {
		data: loggedInUserData,
		loading: loggedInUserLoading,
		error: loggedInUserError,
	} = useQuery(loggedInUser, {
		errorPolicy: 'all',
	});
	const [setNodeDescriptionFn] = useMutation(setNodeDescription);

	async function saveDescription(descriptionPlaintext: string, descriptionHTML: string) {
		await setNodeDescriptionFn({
			variables: {
				descriptionHTML,
				descriptionPlaintext,
				_id: nodeId,
			},
		});
	}

	return (
		<div>
			<LoadingWrapper loading={loading || loggedInUserLoading}>
				<NodeDescription
					bodyHTML={data?.nodeById.descriptionHTML}
					onEdit={(descriptionPlaintext, descriptionHTML) => saveDescription(descriptionPlaintext, descriptionHTML)}
					editable={data?.nodeById?.creator._id === loggedInUserData?.loggedInUser?._id}
				/>
			</LoadingWrapper>
			<Hideable hidden={!error && !loggedInUserError}>
				<div className="error">{error || loggedInUserError}</div>
			</Hideable>
		</div>
	);
}
