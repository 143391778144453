import React, { useState, useRef, useEffect } from 'react';
import { useMutation, ApolloError, MutationFunction } from '@apollo/client';

import Hideable from '/client/app/components/common/hideable';
import { setNodeName } from '/common/graphql/mutations/nodes';
import './nodeNameEditInput.scss';

interface IProps {
	_id: string;
	onSuccessfulEdit: () => void;
	onCancel: () => void;
	name: string;
}

export default function NodeNameEditInput(props: IProps) {
	const { _id, onSuccessfulEdit, onCancel, name } = props;
	const [inputValue, setInputValue] = useState(name);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState<string>();
	const hiddenCharacterRef = useRef(null);
	const containerRef = useRef(null);

	const inputRef = useRef(null);

	useEffect(() => {
		setInputWidth(Math.max(20, inputValue.length + 1));
	});

	async function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>, setNodeNameFn: MutationFunction) {
		if (e.key === 'Enter') {
			const target = e.target as HTMLInputElement;
			if (target.value.trim().length > 0) {
				setSubmitting(true);
				await setNodeNameFn({ variables: { _id, name: target.value } });
			}
			e.preventDefault();
		} else if (e.key === 'Escape') {
			onCancel();
			e.preventDefault();
		}
	}

	function takeFocus() {
		if (inputRef && inputRef.current) {
			// @ts-ignore
			inputRef.current.focus();
		}
	}

	function setInputWidth(charCount: number) {
		if (inputRef && inputRef.current) {
			// Unholy hack to set width without exceeding parent div bounds
			// @ts-ignore
			const maxCharCount = containerRef.current.clientWidth / hiddenCharacterRef.current.getBoundingClientRect().width - 1;
			// @ts-ignore
			inputRef.current.style = `width: ${Math.round(Math.min(maxCharCount, charCount))}ch`;
		}
	}

	function onInputChange(value: string) {
		setInputValue(value);
		setInputWidth(Math.max(20, value.length + 1));
	}

	async function submitSucceeded() {
		await setSubmitting(false);
		onSuccessfulEdit();
	}

	async function submitFailed(apolloError: ApolloError) {
		setError(apolloError.message.replace('GraphQL error: ', ''));
		await setSubmitting(false);
		takeFocus();
	}

	const [setNodeNameFn] = useMutation(setNodeName, { onError: submitFailed, onCompleted: submitSucceeded });

	return (
		<div className="nodeNameEditInput">
			<div ref={containerRef}>
				<input
					ref={inputRef}
					disabled={submitting}
					className="nodeNameEditInput standardInput"
					value={inputValue}
					autoFocus
					onChange={(e) => onInputChange(e.target.value)}
					onKeyDown={(e) => onKeyDown(e, setNodeNameFn)}
				/>
				<span className="hiddenCharacter" ref={hiddenCharacterRef}>
					0
				</span>
			</div>
			<Hideable hidden={!error}>
				<div className="error">
					<span>{error}</span>
				</div>
			</Hideable>
		</div>
	);
}
