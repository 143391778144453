import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Hideable from '/client/app/components/common/hideable';
import { ITreeNode } from '/common/interfaces/tree';
import NewNodeInput from './treeNodeHeader/newNodeInput/newNodeInput';
import TreeNodeHeader from './treeNodeHeader/treeNodeHeader';
import DropPlaceholder, { IProps as PlaceholderProps } from './dropPlaceholder/dropPlaceholder';
import { FilterModeContext } from '../tree';

import './treeNode.scss';
import TreeNodeContainer from '/client/app/components/tree/treeNode/treeNodeContainer';

interface IProps extends ITreeNode {
	_id: string;
	parent: string;
	rootNode: boolean;
	selectedNode?: string;
	setSelectedNode: (selectedNode: string) => any;
	setParentPlaceholderData: (placeholderData: PlaceholderProps) => any;
	index: number;
	path: string;
	distanceFromExpandedParent: number;
	lastStatusModificationDate: number;
}

function TreeNode(props: IProps) {
	const {
		name,
		_id,
		parent,
		index,
		path,
		distanceFromExpandedParent,
		lastStatusModificationDate,
		rootNode,
		editable,
		selectedNode,
		setSelectedNode,
		childNodes,
		parentNodes,
		status,
		type,
		setParentPlaceholderData,
	} = props;
	const [editing, setEditing] = useState(false);
	const [hoveredAbove, setHoveredAbove] = useState(false);
	const [hoveredBelow, setHoveredBelow] = useState(false);
	const [hoveredCenter, setHoveredCenter] = useState(false);
	const [placeholderData, setPlaceholderData] = useState<PlaceholderProps>();
	const [expanded, setExpanded] = useState(rootNode);
	const selected = selectedNode === path;
	const filterMode = useContext(FilterModeContext);

	function selectExpandAndSetPlaceholderData(data: PlaceholderProps) {
		setSelectedNode(path);
		setExpanded(true);
		setPlaceholderData(data);
	}

	function isHidden() {
		if (placeholderData) {
			if (placeholderData._id === _id && placeholderData.parentId === parent && placeholderData.newParentId !== _id)
				return true;
		}

		const isClosed = status === 'resolved' || status === 'rejected';
		if (filterMode === 'open') {
			if (isClosed) return true;
		}

		if (filterMode === 'open & closed today') {
			const timeAsOf24HoursAgo = new Date().getTime() - 60 * 60 * 24 * 1000;
			if (isClosed && (!lastStatusModificationDate || new Date(lastStatusModificationDate).getTime() < timeAsOf24HoursAgo)) {
				return true;
			}
		}

		return false;
	}

	function nodesFromNodeIds(nodeIds: Array<string>) {
		const childElements: JSX.Element[] = [];
		nodeIds.forEach((nodeId: string, childIndex: number) => {
			if (placeholderData && placeholderData._id === nodeId && placeholderData.parentId === _id) return;
			childElements.push(
				<TreeNodeContainer
					selectedNode={selectedNode}
					path={`${path}/${nodeId}`}
					key={nodeId}
					index={childIndex}
					setSelectedNode={setSelectedNode}
					setParentPlaceholderData={setPlaceholderData}
					parent={_id}
					_id={nodeId}
				/>
			);
		});

		if (placeholderData && placeholderData._id !== placeholderData.newParentId) {
			childElements.splice(
				placeholderData.newIndex,
				0,
				<DropPlaceholder
					{...placeholderData}
					key={`placeholder.${placeholderData._id}`}
					removeFn={() => setPlaceholderData(undefined)}
				/>
			);
		}
		return childElements;
	}

	function getNodeIcon(loading: boolean, hasChildren: boolean) {
		let iconClassName = 'fa-genderless';
		if (loading) iconClassName = 'fa-spinner fa-pulse';
		else if (hoveredCenter) iconClassName = 'fa-plus-square';
		else if (hasChildren) iconClassName = expanded ? 'fa-minus-square' : 'fa-plus-square';

		return (
			<button type="button" className="expandCollapse" onClick={hasChildren ? () => setExpanded(!expanded) : undefined}>
				<i className={`${!loading && hasChildren ? 'far' : 'fas'} ${iconClassName} expandIcon`} />
				<i className={`fas ${iconClassName} expandIconSolid`} />
			</button>
		);
	}

	const nodeClasses = ['treeNode', status.replace('in progress', 'inProgress')];
	if (selected) nodeClasses.push('selected');
	if (!expanded) nodeClasses.push('collapsed');
	if (hoveredAbove) nodeClasses.push('hoveredAbove');
	else if (hoveredBelow) nodeClasses.push('hoveredBelow');
	else if (hoveredCenter) nodeClasses.push('hoveredCenter');

	const childNodeComponents = nodesFromNodeIds(childNodes);

	const nodeElement = useMemo(
		() => (
			<div className={nodeClasses.join(' ')}>
				<div className="treeNodeLeft">{getNodeIcon(false, childNodeComponents.length > 0 || selected)}</div>
				<div className="treeNodeRight">
					<TreeNodeHeader
						_id={_id}
						parent={parent}
						path={path}
						index={index}
						lastChildIndex={childNodes.length - 1}
						parentCount={parentNodes.length}
						name={name}
						rootNode={rootNode}
						selected={selected}
						editable={editable}
						editing={editing}
						status={status}
						type={type}
						setParentPlaceholderData={setParentPlaceholderData}
						setPlaceholderData={(data) => selectExpandAndSetPlaceholderData(data)}
						setHoveredAbove={setHoveredAbove}
						setHoveredCenter={setHoveredCenter}
						setHoveredBelow={setHoveredBelow}
						setExpanded={setExpanded}
						setEditing={setEditing}
						setSelectedNode={setSelectedNode}
					/>
					<div className="nodeChildren">
						{expanded || distanceFromExpandedParent < 3 ? childNodeComponents : []}
						<Hideable hidden={!selected || !expanded}>
							<NewNodeInput parentName={name} parentNodeId={_id} />
						</Hideable>
					</div>
				</div>
			</div>
		),
		[
			childNodeComponents,
			selected,
			_id,
			parent,
			path,
			index,
			childNodes,
			parentNodes,
			name,
			rootNode,
			editable,
			editing,
			status,
			setParentPlaceholderData,
			selectExpandAndSetPlaceholderData,
			setHoveredAbove,
			setHoveredCenter,
			setHoveredBelow,
			setExpanded,
			setEditing,
			setSelectedNode,
		]
	);

	return <Hideable hidden={isHidden()}>{() => nodeElement}</Hideable>;
}

export default TreeNode;

TreeNode.propTypes = {
	rootNode: PropTypes.bool,
	_id: PropTypes.string.isRequired,
	parent: PropTypes.string.isRequired,
	selectedNode: PropTypes.string,
	setSelectedNode: PropTypes.func.isRequired,
	editable: PropTypes.bool.isRequired,
	status: PropTypes.string,
	index: PropTypes.number.isRequired,
	childNodes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setParentPlaceholderData: PropTypes.func,
	path: PropTypes.string.isRequired,
	distanceFromExpandedParent: PropTypes.number,
	lastStatusModificationDate: PropTypes.number,
};

TreeNode.defaultProps = {
	rootNode: false,
	selectedNode: null,
	status: 'open',
	setParentPlaceholderData: null,
	distanceFromExpandedParent: 0,
	lastStatusModificationDate: undefined,
};
