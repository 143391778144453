import '@babel/polyfill';
import { hydrate } from 'react-dom';
import IndexView from '/client/app/views/index';
import React from 'react';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import createCache from '/common/graphql/createCache';
import SSRContext from '/common/ssr/SSRContext';

import './index.scss';

const uri = window.__API_URI__ || 'http://localhost:3000/graphql';

console.log(`Connecting to GraphQL endpoint: ${uri}`);

const link = new BatchHttpLink({
	uri,
	credentials: 'include',
});

const client = new ApolloClient({
	link,
	cache: createCache().restore(window.__APOLLO_STATE__),
});

hydrate(
	<ApolloProvider client={client}>
		<SSRContext.Provider value={false}>
			<IndexView />
		</SSRContext.Provider>
	</ApolloProvider>,
	document.getElementById('app')
);

window.BUNDLE_LOADED = true;
