import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, MutationFunction } from '@apollo/client';
import NWayToggle from '/client/app/components/common/nWayToggle/nWayToggle';
import { setTreeViewFilterMode } from '/common/graphql/mutations/treeViews';

import './treeControls.scss';

interface IProps {
	_id: string;
	viewId: string;
	filterMode: string;
	sticky: boolean;
}

export default function TreeControls(props: IProps) {
	const { _id, viewId, filterMode, sticky } = props;
	const filterModes = ['all', 'open', 'open & closed today'];

	async function onSelection(index: number, setTreeViewFilterModeFn: MutationFunction) {
		if (filterModes[index] === filterMode) return;

		await setTreeViewFilterModeFn({
			variables: {
				nodeId: _id,
				filterMode: filterModes[index],
			},
			optimisticResponse: {
				__typename: 'Mutation',
				setTreeViewFilterMode: {
					__typename: 'TreeView',
					_id: viewId,
					filterMode: filterModes[index],
				},
			},
		});
	}

	const [setTreeViewFilterModeFn] = useMutation(setTreeViewFilterMode);

	return (
		<div className={`treeControls${sticky ? ' sticky' : ''}`}>
			<span className="filterModeLabel">Show&nbsp;</span>
			<NWayToggle
				onProgramaticSelect={(index: number) => onSelection(index, setTreeViewFilterModeFn)}
				options={filterModes}
				selectedOption={filterModes.indexOf(filterMode)}
			/>
		</div>
	);
}

TreeControls.propTypes = {
	_id: PropTypes.string.isRequired,
	viewId: PropTypes.string.isRequired,
	filterMode: PropTypes.string,
	sticky: PropTypes.bool,
};

TreeControls.defaultProps = {
	filterMode: 'all',
	sticky: false,
};
