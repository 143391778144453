import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainLayout from './mainLayout';

function MainLayoutContainer(props) {
	return <MainLayout loggedInUserEmail={props.loggedInUserEmail} location={props.location} {...props} />;
}

MainLayoutContainer.propTypes = {
	loggedInUserEmail: PropTypes.string,
	location: PropTypes.string.isRequired,
};

MainLayoutContainer.defaultProps = {
	loggedInUserEmail: null,
};

const mapStateToProps = state => ({
	loggedInUserEmail: state.globalUserState.loggedInUserEmail,
	location: state.router.location ? state.router.location.pathname : '/',
});

export default withRouter(connect(mapStateToProps)(MainLayoutContainer));
