import React from 'react';
import '/client/app/styles/text.scss';

export default function EmailConfirmationFailureView() {
	return (
		<div>
			<p>An error occurred and we were unable to verify your email address.</p>
		</div>
	);
}
