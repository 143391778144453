import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '/client/app/styles/text.scss';
import TreeView from '/client/app/views/treeView';

interface IProps {
	userRootNodeId: string;
}

function UserHomeView(props: IProps) {
	const { userRootNodeId } = props;

	useEffect(() => {
		document.title = 'Simian Stack - User Tree';
	});

	return <TreeView rootNodeId={userRootNodeId} />;
}

const mapStateToProps = (state: any) => ({
	userRootNodeId: state.globalUserState.userRootNodeId,
});

export default connect(mapStateToProps)(UserHomeView);
