import React from 'react';
import PropTypes from 'prop-types';
import '/client/app/styles/text.scss';
import '/client/app/styles/controls.scss';
import InputContainer from '../../components/common/inputs/input/inputContainer';
import './account.scss';

export default class AccountView extends React.Component {
	constructor(props) {
		super(props);
		this.render = this.render.bind(this);
	}

	render() {
		return (
			<div className="accountFormSection">
				<div className="accountFieldGrouping">
					<span className="accountFormName">username </span>
					<InputContainer className="accountFormButton" disabled value={this.props.username} uid="AccountUsernameInput" />
				</div>
				<div className="accountFieldGrouping">
					<span className="accountFormName">email address </span>
					<InputContainer className="accountFormButton" disabled value={this.props.userEmail} uid="AccountEmailInput" />
				</div>
				<div className="accountFieldGrouping changePasswordFieldGrouping">
					<span className="accountFormName">change password</span>
					<br />
					<InputContainer
						className="accountFormButton accountChangePasswordField"
						type="password"
						placeholder="enter new password"
						id="password"
						uid="AccountPasswordInput"
					/>
					<br />
					<InputContainer
						className="accountFormButton accountChangePasswordField"
						type="password"
						placeholder="confirm password"
						id="password2"
						uid="AccountConfirmPasswordInput"
					/>
					<br />
					<button type="button" className="standardButton" onClick={this.createAccount}>
						change password
					</button>
				</div>
			</div>
		);
	}
}

AccountView.propTypes = {
	userEmail: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
};
