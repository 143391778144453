import React from 'react';
import '/client/app/styles/text.scss';
import './privacyPolicy.scss';

export default function PrivacyPolicyView() {
	return (
		<iframe
			width="100%"
			title="Privacy Policy"
			className="embeddedPrivacyPolicy"
			src="https://app.termly.io/document/privacy-policy/040387f1-c096-43d2-bcad-16116eb64554"
			allowFullScreen
		>
			<p>Your browser does not support iframes.</p>
		</iframe>
	);
}
