import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';

import './input.scss';

class Input extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);
		this.input = React.createRef();
	}

	getClassNames() {
		const classNames = ['commonInput'];
		if (this.props.className) classNames.push(this.props.className);
		return classNames;
	}

	getCurrentValue() {
		// TODO: Figure out why ref is missing getText function on first render
		return this.props.value || (this.input.getText ? this.input.getText() : '');
	}

	validateTextInput(e, text) {
		const startPosition = e.target.selectionStart;
		const endPosition = e.target.selectionEnd;
		const currentValue = this.getCurrentValue();
		const possibleFutureValue = currentValue.slice(0, startPosition) + text + currentValue.slice(endPosition);
		const patternRegEx = new RegExp(this.props.pattern);
		if (patternRegEx.test(possibleFutureValue) === false) {
			e.preventDefault();
			return false;
		}
		return true;
	}

	onKeyPress(e) {
		if (this.props.enforcePatternOnChange && this.props.pattern)
			return this.validateTextInput(e, String.fromCharCode(e.charCode));
		return true;
	}

	onPaste(e) {
		if (this.props.enforcePatternOnChange && this.props.pattern) {
			let pastedText;
			if (window.clipboardData && window.clipboardData.getData) {
				// IE
				pastedText = window.clipboardData.getData('Text');
			} else if (e.clipboardData && e.clipboardData.getData) {
				pastedText = e.clipboardData.getData('text/plain');
			}

			return this.validateTextInput(e, pastedText);
		}
		return true;
	}

	renderInput() {
		const { onInput, multiline, inputValue, fieldValueUpdated, enforcePatternOnChange, uid, ...rest } = this.props;
		return (
			<input
				ref={this.input}
				onKeyPress={this.onKeyPress}
				type="text"
				onChange={onInput}
				onPaste={this.onPaste}
				{...rest}
				className={this.getClassNames().join(' ')}
			/>
		);
	}

	renderTextArea() {
		const { onInput, multiline, ...rest } = this.props;
		return (
			<textarea
				ref={this.input}
				onKeyPress={this.onKeyPress}
				onChange={onInput}
				{...rest}
				className={this.getClassNames().join(' ')}
			/>
		);
	}

	render() {
		if (this.props.multiline) return this.renderTextArea();
		return this.renderInput();
	}
}

Input.propTypes = {
	// Hack because bool validation throws a warning when you do the right thing...
	multiline: PropTypes.number,
	className: PropTypes.string,
	onInput: PropTypes.func,
	value: PropTypes.string,
	uid: PropTypes.string,
	enforcePatternOnChange: PropTypes.bool,
	pattern: PropTypes.string,
};

Input.defaultProps = {
	multiline: 0,
	className: null,
	onInput: null,
	value: null,
	uid: '',
	pattern: null,
	enforcePatternOnChange: false,
};

export default Input;
