import { connectRouter } from 'connected-react-router';
import loginFormReducer from './reducers/loginFormReducer/loginFormReducer';
import globalUserStateReducer from './reducers/globalUserStateReducer/globalUserStateReducer';
import createAccountFormReducer from './reducers/createAccountFormReducer/createAccountFormReducer';
import inputValueReducer from './reducers/common/input/inputValueReducer/inputValueReducer';
import fileReducer from './reducers/fileReducer/fileReducer';

export default (history) => ({
	loginFormState: loginFormReducer,
	router: connectRouter(history),
	globalUserState: globalUserStateReducer,
	createAccountFormState: createAccountFormReducer,
	inputValueState: inputValueReducer,
	fileState: fileReducer,
});
