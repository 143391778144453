import React, { useEffect, useRef, useState } from 'react';

import './activeStackFrame.scss';

const EmojiParser = require('emoji-to-short-name');

interface IProps {
	name: string;
}

export default function ActiveStackFrame(props: IProps) {
	const { name } = props;
	const [selected, setSelected] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		document.addEventListener('mousedown', deselectIfClickedOutside);
		document.addEventListener('keypress', deselectOnKeydown);
		return function cleanup() {
			document.removeEventListener('mousedown', deselectIfClickedOutside);
			document.removeEventListener('keypress', deselectOnKeydown);
		};
	});

	function deselectIfClickedOutside(event: any) {
		// @ts-ignore
		if (!ref.current.contains(event.target)) setSelected(false);
	}

	function deselectOnKeydown(event: any) {
		// @ts-ignore
		if (ref.current !== document.activeElement) {
			if (event.charCode === 32 || event.charCode === 13) setSelected(false);
		}
	}

	function select() {
		if (!selected) setSelected(true);
	}

	function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
		if (e.key === ' ' || e.key === 'Enter') select();
	}

	const classNames = ['activeStackFrame'];
	if (selected) classNames.push('selected');

	return (
		<div
			ref={ref}
			tabIndex={0}
			// @ts-ignore
			focusable
			role="option"
			onKeyPress={onKeyPress}
			aria-selected={selected}
			onClick={select}
			className={classNames.join(' ')}
		>
			<div className="name">{EmojiParser.decode(name)}</div>
		</div>
	);
}
