import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	createAccountFormValuesUpdated,
	attemptAccountCreation,
	accountCreationSuccess,
	accountCreationFailure,
} from '/common/reducers/createAccountFormReducer/createAccountFormReducerActions';
import Input from '/client/app/components/common/inputs/input/input';
import { createAccount } from '/client/app/api/backend';
import validator from 'validator';
import '/client/app/styles/controls.scss';
import './createAccountForm.scss';

class CreateAccountForm extends React.Component {
	constructor(props) {
		super(props);
		this.attemtAccountCreation = this.attemtAccountCreation.bind(this);
		this.onUsernameInput = this.onUsernameInput.bind(this);
		this.onEmailInput = this.onEmailInput.bind(this);
		this.onEmailConfirmationInput = this.onEmailConfirmationInput.bind(this);
		this.onPasswordInput = this.onPasswordInput.bind(this);
		this.onPasswordConfirmationInput = this.onPasswordConfirmationInput.bind(this);
		this.updateFormValues = this.updateFormValues.bind(this);
	}

	updateFormValues(changedValues) {
		const currentState = {
			email: this.props.email,
			emailError: this.props.emailError,
			username: this.props.username,
			usernameError: this.props.usernameError,
			emailConfirmation: this.props.emailConfirmation,
			password: this.props.password,
			passwordError: this.props.passwordError,
			passwordConfirmation: this.props.passwordConfirmation,
			passwordConfirmationError: this.props.passwordConfirmationError,
			submitDisabled: this.props.submitDisabled,
			submitButtonText: this.props.submitButtonText,
			error: this.props.error,
		};
		const newValues = { ...currentState, ...changedValues };
		this.props.createAccountFormValuesUpdated(
			newValues.username,
			newValues.email,
			newValues.emailError,
			newValues.emailConfirmation,
			newValues.emailConfirmationError,
			newValues.password,
			newValues.passwordError,
			newValues.passwordConfirmation,
			newValues.passwordConfirmationError,
			newValues.error
		);
	}

	onUsernameInput(e) {
		const usernameIsValid = e.target.value.trim().length > 0;
		const filteredUsername = e.target.value.replace(/[^\w\d_-]/, '');
		this.updateFormValues({
			username: filteredUsername,
			usernameError: usernameIsValid,
		});
	}

	onEmailInput(e) {
		const emailIsInvalid = e.target.value.trim().length > 0 && !validator.isEmail(e.target.value.trim());
		const emailConfirmationIsInvalid =
			e.target.value.trim().length > 0 && e.target.value.trim() !== this.props.emailConfirmation;
		this.updateFormValues({
			email: e.target.value,
			emailError: emailIsInvalid,
			emailConfirmationError: emailConfirmationIsInvalid,
		});
	}

	onEmailConfirmationInput(e) {
		const emailConfirmationIsInvalid =
			this.props.email.trim().length > 0 && this.props.email.trim() !== e.target.value.trim();
		this.updateFormValues({
			emailConfirmation: e.target.value,
			emailConfirmationError: emailConfirmationIsInvalid,
		});
	}

	onPasswordInput(e) {
		const passwordConfirmationIsInvalid = e.target.value.length > 0 && e.target.value !== this.props.passwordConfirmation;
		this.updateFormValues({
			password: e.target.value,
			passwordConfirmationError: passwordConfirmationIsInvalid,
		});
	}

	onPasswordConfirmationInput(e) {
		const passwordConfirmationIsInvalid = this.props.password.length > 0 && this.props.password !== e.target.value.trim();
		this.updateFormValues({
			passwordConfirmation: e.target.value,
			passwordConfirmationError: passwordConfirmationIsInvalid,
		});
	}

	attemtAccountCreation(e) {
		e.preventDefault();

		const eml = this.props.email.trim();
		const passwd = this.props.password;
		const { username } = this.props;

		const _accountCreationFailure = this.props.accountCreationFailure;
		const _accountCreationSuccess = this.props.accountCreationSuccess;

		this.props.attemptAccountCreation();
		createAccount(username, eml, passwd)
			.then(result => {
				if (result === 'success') {
					_accountCreationSuccess();
					window.location.href = this.props.destination;
				} else {
					_accountCreationFailure(result);
				}
			})
			.catch(error => {
				_accountCreationFailure(error.message);
			});
		return false;
	}

	render() {
		return (
			<form onSubmit={this.attemtAccountCreation}>
				<Input
					type="text"
					id="username"
					name="username"
					placeholder="username"
					value={this.props.username}
					onChange={this.onUsernameInput}
					className={`createAccountFormField${this.props.usernameError ? ' createAccountFormFieldInError' : ''}`}
				/>
				<br />
				<Input
					type="text"
					id="email"
					value={this.props.email}
					name="email"
					placeholder="email address"
					onChange={this.onEmailInput}
					className={`createAccountFormField${this.props.emailError ? ' createAccountFormFieldInError' : ''}`}
				/>
				<Input
					type="text"
					id="email2"
					name="email2"
					placeholder="confirm email"
					value={this.props.emailConfirmation}
					onChange={this.onEmailConfirmationInput}
					className={`createAccountFormField${this.props.emailConfirmationError ? ' createAccountFormFieldInError' : ''}`}
				/>
				<br />
				<Input
					type="password"
					id="password"
					name="password"
					placeholder="password"
					value={this.props.password}
					onChange={this.onPasswordInput}
					className={`createAccountFormField${this.props.passwordError ? ' createAccountFormFieldInError' : ''}`}
				/>
				<Input
					type="password"
					id="password2"
					name="password2"
					placeholder="confirm password"
					value={this.props.passwordConfirmation}
					onChange={this.onPasswordConfirmationInput}
					className={`createAccountFormField${this.props.passwordConfirmationError ? ' createAccountFormFieldInError' : ''}`}
				/>
				<br />
				<button
					className="standardButton createAccountButton"
					name="createAccountButton"
					type="submit"
					disabled={this.props.submitDisabled}
				>
					<b>{this.props.submitButtonText}</b>
				</button>
				<p className="createAccountErrorLabel">{this.props.error.toLowerCase()}</p>
			</form>
		);
	}
}

CreateAccountForm.propTypes = {
	email: PropTypes.string.isRequired,
	emailError: PropTypes.bool.isRequired,
	username: PropTypes.string.isRequired,
	usernameError: PropTypes.bool.isRequired,
	emailConfirmation: PropTypes.string.isRequired,
	emailConfirmationError: PropTypes.bool.isRequired,
	password: PropTypes.string.isRequired,
	passwordError: PropTypes.bool.isRequired,
	passwordConfirmation: PropTypes.string.isRequired,
	passwordConfirmationError: PropTypes.bool.isRequired,
	submitDisabled: PropTypes.bool.isRequired,
	submitButtonText: PropTypes.string.isRequired,
	error: PropTypes.string.isRequired,
	attemptAccountCreation: PropTypes.func.isRequired,
	accountCreationSuccess: PropTypes.func.isRequired,
	accountCreationFailure: PropTypes.func.isRequired,
	createAccountFormValuesUpdated: PropTypes.func.isRequired,
	destination: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch => ({
	createAccountFormValuesUpdated: (
		username,
		email,
		emailError,
		emailConfirmation,
		emailConfirmationError,
		password,
		passwordError,
		passwordConfirmation,
		passwordConfirmationError,
		error
	) =>
		dispatch(
			createAccountFormValuesUpdated(
				username,
				email,
				emailError,
				emailConfirmation,
				emailConfirmationError,
				password,
				passwordError,
				passwordConfirmation,
				passwordConfirmationError,
				error
			)
		),
	attemptAccountCreation: () => dispatch(attemptAccountCreation()),
	accountCreationSuccess: () => dispatch(accountCreationSuccess()),
	accountCreationFailure: error => dispatch(accountCreationFailure(error)),
});

export default connect(
	null,
	mapDispatchToProps
)(CreateAccountForm);
