import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logout } from '/client/app/api/backend';
import TextSearchInput from '/client/app/components/search/textSearchInput';
import Hideable from '/client/app/components/common/hideable';

require('./userTopBar.scss');

interface IProps {
	onLogoutSuccess: () => any;
	loggedInUserEmail?: string;
}
export default function UserTopBar(props:IProps) {
	const {onLogoutSuccess, loggedInUserEmail} = props;

	function signOut() {
		logout()
			.then(success => {
				if (success) onLogoutSuccess();
				else console.log('Unspecified error signing out.');
			})
			.catch(error => {
				console.log(`Error signing out: ${error.message}`);
			});
	}

	return (
		<div className="userTopBar">
			<div className="userTopBarHeader">
				<Link title="user home" className="userTopBarBrandLink" to={{ pathname: '/main' }}>
					<img className="userTopBarBrand" alt="Brand" src="/static/img/brand.svg" />
					<img className="userTopBarBrandDark" alt="Brand" src="/static/img/brandDark.svg" />
					<span className="userTopBarBrandTitle">simian stack</span>
				</Link>
				<span className="userTopBarRight">
						<Hideable hidden={!loggedInUserEmail}>
							<span className="leftSearchBar">
								<TextSearchInput />
							</span>
							&nbsp;
							<Link title="feedback" to={{ pathname: '/feedback' }}>
								<i className="fas fa-bug" />
							</Link>
							&nbsp;
							<Link title="account" to={{ pathname: '/account' }}>
								<i className="fas fa-user-circle" />
							</Link>
							&nbsp;
							<Link title="preferences" to={{ pathname: '/preferences' }}>
								<i className="fas fa-cog" />
							</Link>
							&nbsp;
						</Hideable>
						<a
							href={loggedInUserEmail ? '#' : '/signin'}
							target="_self"
							id="signInSignOut"
							title={loggedInUserEmail ? 'sign out' : 'sign in'}
							onClick={loggedInUserEmail ? signOut : undefined}
						>
							<i className={`fas fa-sign-${loggedInUserEmail ? 'out' : 'in'}-alt`} aria-hidden="true" />
						</a>
					</span>
			</div>
			<Hideable hidden={!loggedInUserEmail}>
				<div className="bottomSearchBar">
					<TextSearchInput />
				</div>
			</Hideable>
		</div>
	);
}

UserTopBar.propTypes = {
	loggedInUserEmail: PropTypes.string,
	onLogoutSuccess: PropTypes.func.isRequired,
};

UserTopBar.defaultProps = {
	loggedInUserEmail: null,
};
