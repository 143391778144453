import React from 'react';
import PropTypes from 'prop-types';
import useCachedQuery from '/common/graphql/useCachedQuery';
import { nodeById } from '/common/graphql/queries/nodes';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import TreeNode from './treeNode';
import { IProps as PlaceholderProps } from './dropPlaceholder/dropPlaceholder';

interface IProps {
	_id: string;
	path: string;
	parent: string;
	selectedNode?: string;
	index: number;
	setSelectedNode: (selectedNode: string) => void;
	setParentPlaceholderData: (placeholderData: PlaceholderProps) => any;
	distanceFromExpandedParent: number;
}

export default function TreeNodeContainer(props: IProps) {
	const {
		_id,
		path,
		selectedNode,
		parent,
		index,
		setSelectedNode,
		setParentPlaceholderData,
		distanceFromExpandedParent,
	} = props;
	const { data, loading } = useCachedQuery(nodeById, { errorPolicy: 'all', variables: { _id } });
	return (
		<LoadingWrapper loading={loading}>
			{() => {
				const { nodeById: nodeData } = data;
				return (
					<TreeNode
						_id={_id}
						path={path}
						selectedNode={selectedNode}
						parent={parent}
						index={index}
						setSelectedNode={setSelectedNode}
						setParentPlaceholderData={setParentPlaceholderData}
						distancedFromExpandedParent={distanceFromExpandedParent}
						{...nodeData}
					/>
				);
			}}
		</LoadingWrapper>
	);
}

TreeNodeContainer.propTypes = {
	_id: PropTypes.string.isRequired,
	selectedNode: PropTypes.string,
	parent: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	setSelectedNode: PropTypes.func.isRequired,
	setParentPlaceholderData: PropTypes.func,
	distanceFromExpandedParent: PropTypes.number,
};

TreeNodeContainer.defaultProps = {
	setParentPlaceholderData: undefined,
	distanceFromExpandedParent: 0,
	selectedNode: undefined,
};
