import React from 'react';
import PropTypes from 'prop-types';

import './nodeDetails.scss';
import Comments from '/client/app/components/comments/comments';
import NodeDescriptionContainer from '/client/app/components/nodeDescription/nodeDescriptionContainer';

interface IProps {
	nodeId: string;
	creationDate: number;
	name: string;
	status: string;
	lastStatusModificationDate: number;
}

export default function NodeDetails(props: IProps) {
	const { creationDate, name, status, lastStatusModificationDate, nodeId } = props;

	const parsedCreationDate = new Date(creationDate);
	const parsedLastStatusModificationDate = new Date(lastStatusModificationDate || creationDate);

	const creationDateStr = `${parsedCreationDate.toDateString()} at ${parsedCreationDate.toLocaleTimeString()}`;
	const lastStatusModificationDateStr = `${parsedLastStatusModificationDate.toDateString()} at ${parsedLastStatusModificationDate.toLocaleTimeString()}`;

	return (
		<div className="nodeDetailsPane">
			<div className="body">
				<div className="nodeName">{name}</div>
				<div>
					<span className="fieldLabel">Status: </span>
					{status}
				</div>
				<div>
					<span className="fieldLabel">Last status change: </span>
					{lastStatusModificationDateStr}
				</div>
				<div>
					<span className="fieldLabel">Created: </span>
					{creationDateStr}
				</div>
				<b>Description:</b>
				<NodeDescriptionContainer nodeId={nodeId} />
				<br />
				<Comments nodeId={nodeId} />
			</div>
		</div>
	);
}

NodeDetails.propTypes = {
	nodeId: PropTypes.string.isRequired,
};
