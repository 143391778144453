import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Value } from 'slate';
import Plain from 'slate-plain-serializer';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import SlateEditor, { defaultValue } from '/client/app/components/common/inputs/slateEditor/slateEditor';

interface IProps {
	submit: (plaintextValue: string, htmlValue: string) => any;
}

export default function NewCommentForm({ submit }: IProps) {
	const [bodyValue, setBodyValue] = useState(defaultValue);
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
	const [key, setKey] = useState(0);

	async function doSubmit() {
		setSubmitting(true);
		try {
			await submit(Plain.serialize(bodyValue), htmlSerializer.serialize(bodyValue));
			setError(null);
			setKey(key + 1);
		} catch (err: any) {
			console.error('Error!', err);
			setError(err.message);
		}
		setSubmitting(false);
	}

	return (
		<div id="newCommentForm">
			<SlateEditor key={key} onChange={(valueJS: any) => setBodyValue(Value.fromJS(valueJS))} />
			<button disabled={submitting} type="button" onClick={() => doSubmit()}>
				{submitting ? 'adding comment...' : 'add comment'}
			</button>
			<div className="error">{error || null}</div>
		</div>
	);
}

NewCommentForm.propTypes = {
	submit: PropTypes.func.isRequired,
};
