import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateAccountForm from './createAccountForm';

function CreateAccountFormContainer(props) {
	return <CreateAccountForm {...props} />;
}

CreateAccountFormContainer.propTypes = {
	email: PropTypes.string.isRequired,
	emailError: PropTypes.bool.isRequired,
	username: PropTypes.string.isRequired,
	usernameError: PropTypes.bool.isRequired,
	emailConfirmation: PropTypes.string.isRequired,
	emailConfirmationError: PropTypes.bool.isRequired,
	password: PropTypes.string.isRequired,
	passwordError: PropTypes.bool.isRequired,
	passwordConfirmation: PropTypes.string.isRequired,
	passwordConfirmationError: PropTypes.bool.isRequired,
	submitDisabled: PropTypes.bool.isRequired,
	submitButtonText: PropTypes.string.isRequired,
	error: PropTypes.string.isRequired,
	destination: PropTypes.string,
};

CreateAccountFormContainer.defaultProps = {
	destination: '/main',
};

const mapStateToProps = state => ({
	email: state.createAccountFormState.email,
	emailError: state.createAccountFormState.emailError,
	username: state.createAccountFormState.username,
	usernameError: state.createAccountFormState.usernameError,
	emailConfirmation: state.createAccountFormState.emailConfirmation,
	emailConfirmationError: state.createAccountFormState.emailConfirmationError,
	password: state.createAccountFormState.password,
	passwordError: state.createAccountFormState.passwordError,
	passwordConfirmation: state.createAccountFormState.passwordConfirmation,
	passwordConfirmationError: state.createAccountFormState.passwordConfirmationError,
	submitDisabled: state.createAccountFormState.submitDisabled,
	submitButtonText: state.createAccountFormState.submitButtonText,
	error: state.createAccountFormState.error,

	formState: state.createAccountFormState,
});

export default connect(mapStateToProps)(CreateAccountFormContainer);
