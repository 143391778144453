import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Tree from '/client/app/components/tree/tree';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import '/client/app/styles/text.scss';
import { nodesBySearchText } from '/common/graphql/queries/nodes';
import { ITreeNode } from '/common/interfaces/tree';
import SearchHighlightContext from '/client/app/components/search/searchHighlightContext';

interface IProps {
	urlParameters: any;
}

interface SearchData {
	nodesBySearchText: ITreeNode[];
}

function treesFromData(data: SearchData, setSelectedNode: (node: string) => any, selectedNode: string | undefined) {
	if (data.nodesBySearchText.length > 0)
		return data.nodesBySearchText.map((node) => (
			<Tree
				showControls={false}
				setSelectedNode={setSelectedNode}
				selectedNode={selectedNode}
				key={node._id}
				rootNodeID={node._id}
			/>
		));
	return <span>No results found</span>;
}

function SearchView(props: IProps) {
	const { urlParameters } = props;
	const { searchString } = urlParameters;

	useEffect(() => {
		document.title = `Simian Stack - ${searchString}`;
	});

	const [selectedNode, setSelectedNode] = useState<string>();

	const { data, loading, error } = useQuery(nodesBySearchText, { errorPolicy: 'all', variables: { searchString } });

	console.log('Error', error);

	const searchData = error ? { nodesBySearchText: [] } : data;

	return (
		<div>
			<div className="searchHeader">{`Search results for "${searchString}":`}</div>
			<LoadingWrapper loading={loading}>
				{() => (
					<SearchHighlightContext.Provider value={searchString}>
						{treesFromData(searchData, setSelectedNode, selectedNode)}
					</SearchHighlightContext.Provider>
				)}
			</LoadingWrapper>
		</div>
	);
}

export default SearchView;
