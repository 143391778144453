import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginForm from './loginForm';

function LoginFormContainer({ email, password, submitButtonText, submitDisabled, destination, error }) {
	return (
		<LoginForm
			email={email}
			password={password}
			submitButtonText={submitButtonText}
			submitDisabled={submitDisabled}
			error={error}
			destination={destination}
		/>
	);
}

LoginFormContainer.propTypes = {
	email: PropTypes.string,
	password: PropTypes.string,
	submitButtonText: PropTypes.string.isRequired,
	submitDisabled: PropTypes.bool,
	error: PropTypes.string,
	destination: PropTypes.string,
};

LoginFormContainer.defaultProps = {
	email: '',
	password: '',
	submitDisabled: true,
	error: null,
	destination: '/main',
};

const mapStateToProps = state => ({
	email: state.loginFormState.email,
	password: state.loginFormState.password,
	submitButtonText: state.loginFormState.submitButtonText,
	submitDisabled: state.loginFormState.submitDisabled,
	error: state.loginFormState.error,
});

export default connect(mapStateToProps)(LoginFormContainer);
