import GoogleAnalytics from '@redux-beacon/google-analytics';
import { configureStore } from '@reduxjs/toolkit';
import * as ReactGA from 'react-ga';
import { combineReducers } from 'redux';
import { createMiddleware, createMetaReducer } from 'redux-beacon';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createReducers from './reducers';
import eventsMap from './analyticsEventMap';
import mainSaga from './sagas';

export default function createStoreWithHistory(history) {
	const reducer = combineReducers(createReducers(history));

	ReactGA.initialize('UA-145586699-1');
	const ga = GoogleAnalytics();
	const gaMiddleware = createMiddleware(eventsMap, ga);
	createMetaReducer(eventsMap, ga);

	const rootReducer = (state, action) => {
		// Revert to initial state on logout
		if (action.type === 'LOGOUT_SUCCESS') {
			return reducer(undefined, action);
		}
		return reducer(state, action);
	};

	const sagaMiddleware = createSagaMiddleware();

	const middleware = [routerMiddleware(history), gaMiddleware, sagaMiddleware];

	if (process.env.NODE_ENV === `development`) {
		const logger = createLogger({
			diff: true,
			predicate: (getState, action) => action.type !== 'REDUX_STORAGE_SAVE',
		});
		middleware.push(logger);
	}

	const preloadedState = typeof window !== 'undefined' ? window.PRELOADED_SIMIAN_STACK_STATE : {};

	const store = configureStore({ reducer: rootReducer, preloadedState, middleware });

	sagaMiddleware.run(mainSaga);

	return store;
}
