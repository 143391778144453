import React, { useState } from 'react';
import { Value } from 'slate';
import { useMutation } from '@apollo/client';
import { editComment, deleteComment } from '/common/graphql/mutations/comments';
import './comment.scss';
import SlateEditor from '/client/app/components/common/inputs/slateEditor/slateEditor';
import htmlSerializer from '/client/app/components/common/inputs/slateEditor/htmlSerializer';
import Plain from 'slate-plain-serializer';
import Hideable from '/client/app/components/common/hideable';

interface IProps {
	id: string;
	bodyHTML: string;
	username: string;
	editable: boolean;
	editDate: number;
	creationDate: number;
}

export default function Comment(props: IProps) {
	const { bodyHTML, username, editable, id, editDate, creationDate } = props;

	const [error, setError] = useState<string | undefined>();
	const [editCommentFn] = useMutation(editComment);
	const [deleteCommentFn] = useMutation(deleteComment);

	async function save(value: Value) {
		await editCommentFn({
			variables: {
				bodyHTML: htmlSerializer.serialize(value),
				bodyPlaintext: Plain.serialize(value),
				id,
			},
		});
		setError(undefined);
	}

	function editCancel() {
		setError(undefined);
	}

	async function onDelete() {
		await deleteCommentFn({
			variables: {
				id,
			},
		});
		setError(undefined);
	}

	return (
		<div className="comment">
			<div className="header">
				<b>{username}</b>
				<Hideable hidden={!creationDate}>&nbsp;@&nbsp;{new Date(creationDate).toLocaleString()}</Hideable>
				<Hideable hidden={!editDate}>
					&nbsp;<i>[edited&nbsp;{new Date(editDate).toLocaleString()}]</i>
				</Hideable>
			</div>
			<SlateEditor
				className="body"
				onSave={(value: Value) => save(value)}
				onDelete={() => onDelete()}
				onError={(err: Error) => setError(err.message)}
				onEditCancel={() => editCancel()}
				displayOnly
				editOnMount={false}
				editable={editable}
				showEditableBorder={false}
				placeholder="detailed description"
				initialValue={htmlSerializer.deserialize(bodyHTML)}
			/>
			<Hideable hidden={!error}>
				<div className="error">{error}</div>
			</Hideable>
		</div>
	);
}
