import gql from 'graphql-tag';

export const treeViewForNode = gql`
	query nodeViews($nodeId: String!) {
		treeViewForNode(nodeId: $nodeId) {
			_id
			filterMode
		}
	}
`;
