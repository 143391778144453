import React from 'react';
import PropTypes from 'prop-types';
import useCachedQuery from '/common/graphql/useCachedQuery';
import { nodeById } from '/common/graphql/queries/nodes';
import LoadingWrapper from '/client/app/components/loading/loadingWrapper';
import NodeDetails from '/client/app/components/nodeDetails/nodeDetails';

interface IProps {
	nodeId: string;
}

export default function NodeDetailsContainer(props:IProps) {
	const { nodeId } = props;
	const { data, loading, error } = useCachedQuery(nodeById, { errorPolicy: 'all', variables: { _id: nodeId } });

	return (
		<div>
			<LoadingWrapper loading={loading}>
				{() => error ? error.message : <NodeDetails lastStatusModificationDate={data.nodeById.lastStatusModificationDate} creationDate={data.nodeById.creationDate} status={data.nodeById.status}  name={data.nodeById.name} nodeId={nodeId} />}
			</LoadingWrapper>
		</div>
	)
}

NodeDetailsContainer.propTypes = {
	nodeId: PropTypes.string.isRequired,
};