import gql from 'graphql-tag';

export const setNodeStatus = gql`
	mutation SetNodeStatus($_id: String!, $status: String!) {
		setNodeStatus(_id: $_id, status: $status) {
			_id
			status
			lastStatusModificationDate
		}
	}
`;

export const deleteNode = gql`
	mutation DeleteNode($_id: String!) {
		deleteNode(_id: $_id) {
			_id
			status
			childNodes
			parentNodes
		}
	}
`;

export const setNodeName = gql`
	mutation SetNodeName($_id: String!, $name: String!) {
		setNodeName(_id: $_id, name: $name) {
			_id
			name
		}
	}
`;

export const setNodeType = gql`
	mutation SetNodeType($_id: String!, $type: String!) {
		setNodeType(_id: $_id, type: $type) {
			_id
			name
			type
		}
	}
`;

export const setNodeDescription = gql`
	mutation SetNodeDescription($_id: String!, $descriptionPlaintext: String!, $descriptionHTML: String!) {
		setNodeDescription(_id: $_id, descriptionPlaintext: $descriptionPlaintext, descriptionHTML: $descriptionHTML) {
			_id
			descriptionPlaintext
			descriptionHTML
		}
	}
`;

export const createNodeUnderNode = gql`
	mutation CreateNodeUnderNode($parentNodeId: String!, $name: String!) {
		createNodeUnderNode(parentNodeId: $parentNodeId, name: $name) {
			_id
			name
			editable
			childNodes
			parentNodes
			name
			status
			lastStatusModificationDate
		}
	}
`;

export const moveNode = gql`
	mutation MoveNode($_id: String!, $parentId: String!, $newParentId: String!, $newIndex: Int!) {
		moveNode(_id: $_id, parentId: $parentId, newParentId: $newParentId, newIndex: $newIndex) {
			_id
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
		}
	}
`;

export const linkNode = gql`
	mutation LinkNode($_id: String!, $newParentId: String!, $newIndex: Int!) {
		linkNode(_id: $_id, newParentId: $newParentId, newIndex: $newIndex) {
			_id
			name
			editable
			status
			childNodes
			parentNodes
			lastStatusModificationDate
		}
	}
`;

export const removeNodeFromParent = gql`
	mutation RemoveNodeFromParent($_id: String!, $parentId: String!) {
		removeNodeFromParent(_id: $_id, parentId: $parentId) {
			_id
			childNodes
			parentNodes
		}
	}
`;
